import { collection, getDocs } from 'firebase/firestore';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { db } from '../../schoolBackend/schoolFirebase';
import { testAuthentication } from '../../firebase/Authentication';
import { ReportCreation } from '../ReportCreation/ReportCreation';

export const UserPick = () => {

  const [alluserdata,setData] =useState([]);
  const [alldata,setAllData] =useState([]);
  const [looding,setlooding] =useState(true);
  const [modif,setModif] =useState([]);


  const getData =async () =>{
   
    let arr = [];
 await   getDocs(collection(db, 'users')).then((res)=>{
      res.forEach((doc,i) => {
        arr.push({...doc.data(),id: doc.id});
    });
    setAllData(arr)
    }).catch((err)=>{ toast.error(err.message)})
    
  }

const test  = () =>{
  let arr = [];
alldata.forEach(user =>{
  arr.push(
    {
      name: user.name,
      id: user.id,
      email:user.email,
      gender:user.gender,
      stage:user.stage,
      ownPackage:user.ownPackage,
    
    
    }
  )
})
setModif(arr);

}

const handelEdit = () =>{
  getData().then(() =>{
    test()
  })
}



  return (
    <div>UserPick


      <button className='btn btn-primary ' onClick={handelEdit} >Pick</button>
     <ReportCreation head="lol" data={modif} />
    </div>
  )
}


