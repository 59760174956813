import React, { useState } from 'react'
import { BiMale } from 'react-icons/bi'
import { FaFemale } from 'react-icons/fa'
import { AddUserToFb } from '../../../../firebase/AddUserToFb';
export const FormOneStudent = () => {
    const intialOneUser = {
        name: "",
        stage: "",
        gender: "male",
        email: "",
        id:"",
        uid:"",
        ownPackage: '',
        show: "",
        lastCheckOut: 0,
        allPatment: [],
        wishlist: [],
      };

      const [OneUserData, setOneUserData] = useState(intialOneUser);

      const handelSubmitOneUser = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let datapass = {
                name: OneUserData.name,
                stage: OneUserData.grade,
                gender: OneUserData.gender,
                email: OneUserData.id+"@school.sys",
                id:OneUserData.id,
                uid:"",
                ownPackage: OneUserData.ownPackage,
                show: "",
                lastCheckOut: 0,
                allPatment: [],
                wishlist: [],
        }
        AddUserToFb(datapass,OneUserData.id)
        setOneUserData(intialOneUser)
      };
    const Menu = [
    {grade: "KG", color: "bg-success-subtle"},
    {grade: "Prim", color: "bg-info-subtle"},
    {grade: "Prep", color: "bg-warning-subtle"},

 ]
const vaildation = (OneUserData) =>{
    if(OneUserData.id.length < 11 && OneUserData.id.length > 11 ){return false}
}
  return (
    <form onSubmit={(e) => handelSubmitOneUser(e)}>
    <div className="form-floating mb-3">
    <input type="text" className="form-control"   placeholder="" id="floatingname validationname " value={OneUserData.name} onChange={(e)=>setOneUserData({...OneUserData,name:e.target.value})} required />
    <label htmlFor="floatingname validationname  " className="form-label">Full name</label>
    </div>
    <div className="form-floating mb-3">
    <input type="text" className={`form-control `} placeholder="000000000" id="floatingId validationId " value={OneUserData.id} onChange={(e)=>setOneUserData({...OneUserData,id:e.target.value})} required />
    <label htmlFor="floatingId validationId" className="form-label">ID Number</label>
    </div>
   
    <div className="dropdown bg-light-subtle   bg-body-secondary mb-3 w-100">
  <button className="btn dropdown-toggle w-100  btn-secondary" data-bs-toggle="dropdown" aria-expanded="false">
    {OneUserData.grade?OneUserData.grade:"Chosse the grade"}
  </button>

  <ul className="dropdown-menu w-100">
        {Menu?Menu.map((el,i)=>{
            return <li><button type='button' onClick={()=>setOneUserData({...OneUserData,grade:el.grade})}  className={`dropdown-item ${el.color} hover-bg-danger`} href="#">{el.grade}</button></li>
        }):""}
  </ul>
</div>

    <div className="mb-3">
    <input type="radio" className="btn-check" name="options-gender" id="success-outlined"  autocomplete="off" defaultChecked onClick={()=>setOneUserData({...OneUserData,gender:'male'})}/>
<label className="btn btn-outline-primary me-3 " htmlFor="success-outlined"><BiMale /> Boy </label>

<input type="radio" className="btn-check" name="options-gender" id="danger-outlined" autocomplete="off" onClick={()=>setOneUserData({...OneUserData,gender:'female'})} />
<label className="btn btn-outline-danger" htmlFor="danger-outlined"><FaFemale /> Girl</label>
    </div>
    <div className="mb-3">
      Own Package:
    <input type="radio" className="btn-check" name="options-own" id="success-own"  autocomplete="off" defaultChecked onClick={()=>setOneUserData({...OneUserData,ownPackage:'yes'})}/>
<label className="btn btn-outline-primary me-3 " htmlFor="success-own"><BiMale /> yes </label>

<input type="radio" className="btn-check" name="options-own" id="danger-own" autocomplete="off" onClick={()=>setOneUserData({...OneUserData,ownPackage:'no'})} />
<label className="btn btn-outline-danger" htmlFor="danger-own"><FaFemale /> no</label>
    </div>
    <button type='submit' className="btn btn-primary w-100">
      Submit
    </button>
  </form>
  )
}
