import React, { useEffect, useState } from "react";
import { TbChartPie, TbShoppingCart } from "react-icons/tb";
import { DateRangePicker } from "rsuite";
import { startOfDay, endOfDay, addDays, subDays } from 'date-fns';

export const ReportCardOrders = ({data}) => {

    const [valuesTimeRange,setValuesTimeRange] = useState([])
    const [valuesData,setValuesData] = useState({
        all:0,
        pending:0,
        paid:0,
        picked:0,
        partlyPicked:0,
        refunding:0,
    })

    useEffect(() => {
        let arr = []
        if(data){
            let all = data,
            pending = all.filter(el => el.status === 'pending').length,
            paid = all.filter(el => el.status === 'paid').length,
            picked = all.filter(el => el.status === 'picked' || el.status === 'partly-picked' ).length,
            partlyPicked = all.filter(el => el.status === 'partly-picked' ).length,
            refunding = all.filter(el => el.status === 'refunding').length;
            setValuesData({
                all:all.length,
                pending,
                paid,
                picked,
                partlyPicked,
                refunding,
            })

            if(data.length > 0){
              if(valuesTimeRange === ''){
                return data
              } else if (valuesTimeRange.length > 0) {
                let startDate = new Date(valuesTimeRange[0])
                startDate.setHours(0,0,0,0)
                let endDate =new Date(valuesTimeRange[1]);
          
           let range = all?.filter(el => {
                  let date = new Date(el.payDate?.toDate())
                  return  new Date(date) >= new Date(startDate) &&  new Date(date) <= new Date(endDate)
                })
                setValuesData({
          
              })
              }
            }

        }
    }, [data,valuesTimeRange])


  return (
    <div className="report-card paid-reports col-6 px-2 h-100">
      <div className=" p-4 h-100 rounded-2" style={{background:"#ffffff"}} >
        <div className="d-flex justify-content-between align-items-center">
          <div className=" p-2  rounded-3 d-flex justify-content-center align-items-center  bg-info bg-opacity-10 text-info">
          <TbShoppingCart className="fs-6" /> <span className="fs-7 ms-2">Orders</span>  
          </div>
          <div className="p-2  rounded-3 d-flex justify-content-center align-items-center  text-dark text-opacity-50 fw-bolder">
            {/* <DateRangePicker
            placeholder="All Time"
            onChange={(e)=>{setValuesTimeRange([
              startOfDay((new Date(e[0]))),
              endOfDay((new Date(e[1])))
            ])}}
            onClean={()=>{setValuesTimeRange([])}}
            showOneCalendar 
            placement='leftStart' 
            /> */}
          </div>
        </div>
        <div className="d-flex mt-5 justify-content-between align-items-center">
          <div className=" p-2  rounded-3 d-flex flex-column  justify-content-center align-items-start ">
            <h6 className="text-opacity-25  text-dark text-start p-0 mb-3">
             All
            </h6>
            <h6 className="text-opacity-75 text-dark">
              {" "}
              {valuesData.all}
            </h6>
          </div>
          <div className=" p-2  rounded-3 d-flex flex-column  justify-content-center align-items-start ">
            <h6 className="text-opacity-25 text-dark text-start p-0 mb-3">
              pending
            </h6>
            <h6 className="text-opacity-75 text-dark">
              {" "}
              {valuesData.pending}
            </h6>
          </div>
          <div className=" p-2  rounded-3 d-flex flex-column  justify-content-center align-items-start ">
            <h6 className="text-opacity-25 text-dark text-start p-0 mb-3">
            paid
            </h6>
            <h6 className="text-opacity-75 text-dark">
              {" "}
              {valuesData.paid}
            </h6>
          </div>
          <div className=" p-2  rounded-3 d-flex flex-column  justify-content-center align-items-start ">
            <h6 className="text-opacity-25 text-dark text-start p-0 mb-3">
              picked
            </h6>
            <h6 className="text-opacity-75 text-dark">
              {" "}
              {valuesData.picked}
            </h6>
          </div>
          <div className=" p-2  rounded-3 d-flex flex-column  justify-content-center align-items-start ">
            <h6 className="text-opacity-25 text-dark text-start p-0 mb-3">
              partly Picked
            </h6>
            <h6 className="text-opacity-75 text-dark">
              {" "}
              {valuesData.partlyPicked}
            </h6>
          </div>
          <div className=" p-2  rounded-3 d-flex flex-column  justify-content-center align-items-start ">
            <h6 className="text-opacity-25 text-dark text-start p-0 mb-3">
              refunding
            </h6>
            <h6 className="text-opacity-75 text-dark">
              {" "}
              {valuesData.refunding}
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};
