import React, { useState } from 'react'
import { BsFillFileEarmarkSpreadsheetFill} from 'react-icons/bs'
import { jsonToCSV, useCSVDownloader } from 'react-papaparse';
import { toast } from 'react-toastify';
export const ReportCreation = ({head,data}) => {


    const { CSVDownloader, Type } = useCSVDownloader();
    const [dataDownloaded,setdataDownloaded] = useState([])


  return (
    <div className='reports px-2 py-2 text-capitalize d-flex justify-content-between align-items-center bg-success bg-opacity-10 rounded shadow btn'
    >

        <span className='text-dark text-opacity-75'>  
        {data?.length > 0 ?
         <CSVDownloader
      type={Type.Link}
      filename={'all users'}
      config={{
        delimiter: ';',
      }}
      bom={true}
      data={data?jsonToCSV(JSON.stringify(data)):toast.error("no data found")}
    >
      {head}
    </CSVDownloader>:""} </span>
        <span className='text-success text-opacity-50'><BsFillFileEarmarkSpreadsheetFill /></span>
    </div>
  )
}
