import { serverTimestamp } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { ReportCreation } from '../ReportCreation/ReportCreation'

export const ComReports = ({orders}) => {
    const [data,setData] = useState([])

useEffect(() => {
    const  prepdata = () =>{
        let arr = []
        orders.forEach(order =>{
            arr.push({
                studentId:order.studentId,
                name:order.name,
                cost:order.total,
                payDate:order.payDate?.toDate().toString().slice(0,24) || null
            })
        })
        arr.push({
            studentId:'',
            name:'Total Amount',
            cost:orders.reduce((total,el)=> total + el.total,0),
            payDate:''
        })
         setData(arr)
    }
    prepdata()
}, [orders])



  return (
    <div className='w-100'>
        ComReports
        <hr/>
        <button className='btn btn-danger px-5 py-1' >GO</button>
        <ReportCreation head="aaaaaaaa" data={data} />
    </div>
  )
}
