import React, { useEffect, useState } from 'react'
import { Loader } from 'rsuite'

export const TabFullCommercial = ({orders}) => {
    const [dataTable,setDataTable] = useState([])
    useEffect(() => {
      const  prepdata = () =>{
          let arr = []
          orders.forEach(order =>{
              arr.push({
                  studentId:order.studentId,
                  name:order.name,
                  cost:order.total,
                  payDate:order.payDate?.toDate().toString().slice(0,24) || null
              })
          })
          arr.push({
              studentId:'',
              name:'Total Amount',
              cost:orders.reduce((total,el)=> total + el.total,0),
              payDate:'',
              nodes:"3"
          })
          setDataTable(arr)
      }
      prepdata()
  }, [orders])


const sortType = (type,keyObj) =>{
    console.log(type,keyObj)
    if(type === 'number'){   
        let arr = dataTable.sort((a,b) => a[`${keyObj}`] - b[`${keyObj}`])
        console.log(arr)
        setDataTable(arr)
    }
    else if(type === 'number'){

        let arr = dataTable.sort((a,b) => { 
               if (a[`${keyObj}`] > b[`${keyObj}`] ) {
            return -1;
        }
        if (a[`${keyObj}`] < b[`${keyObj}`]) {
            return 1;
        }
        return 0
    })
    setDataTable(arr)

    }


}






/**
 *              studentId:order.studentId,
                name:order.name,
                cost:order.total,
                payDate:order.payDate?.toDate().toString().slice(0,24) || null
 */









let columns = [
    {
        label:'Student ID',
        keyObj:'studentId',
        type:'number'
    },
    {
        label:'Name',
        keyObj:'name',
        type:'number'
    },
    {
        label:'Price',
        keyObj:'cost',
        type:'number'
    },
    {
        label:'Pay Date',
        keyObj:'payDate',
        type:'number'
    },
]



    

    //sortType = (type,data,keyObj) 
  return (
    <div className='TabFullCommercial w-100'>
       <div id="full-com-table">
       <table className="table utable pe-5">
  <thead className='utable-head '> 
    <tr >
  {columns?.map((el,i)=>{return(
          <td  className="col" onClick={()=>{sortType(el.type,el.keyObj)}}>{el.label}</td >
      )
    })
      }
</tr>
  </thead>
  <tbody className='utable-head '>
      {dataTable?.map((el,i)=>{return(<>
        <tr className={`${el.stock === 'out'?'table-danger':''}`}>
          <td  className="col">{el.studentId}</td >
          <td  className="col">{el.name}</td >
          <td  className="col">{el.cost}</td >
          <td  className="col">{el.payDate}</td >
        </tr>
        </>
      )
    })
      }
  </tbody>

  </table>
       </div>
    </div>
  )
}
