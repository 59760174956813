import React, { useEffect, useState } from 'react'
import './Login.css'
import logo from '../../assets/imgs/logo.png'
import { Authentication } from '../../firebase/Authentication'
import { useNavigate } from 'react-router'
export const Login = () => {

    const intialLogin = {
        email:"",password:""
    }
    const [email,setemail]  = useState('')
    const [password,setpassword]  = useState('')
    const [message,setMessaging]  = useState({type:'',message:""})
    const [data,setData]  = useState(intialLogin)
    const navigate = useNavigate();
    useEffect(() => {
      if(message.type === 'is-valid'){
        data.then((result) => {
          localStorage.setItem('auth', JSON.stringify(result));
          navigate('/');
    
        }).catch((err) => {
          console.log(err)
        });
      }
    
    }, [message.type]);


    const handelSubmit = (e)=>{
        e.preventDefault();
        e.stopPropagation()
        Authentication(email,password,setMessaging,setData)

    }
  return (
    <div className='login d-flex ' >
        <div className="col-6 h-100 d-flex justify-content-center align-items-center">
            <form className='w-75' onSubmit={(e)=>{handelSubmit(e);}}>
                <div className='form-header '>
                    <h1 className='login-head'>Welcome back</h1>
                    <p className='login-p'>Welcome back!Please enter your details</p>
                </div>

                <div className="form-floating mb-3">
  <input type="email" className={`form-control ${message.type}`} id="floatingInput validationEmail" placeholder="name@example.com" value={email} onChange={(e)=>{setemail(e.target.value)}}/>
  <label htmlFor="floatingInput validationEmail">Email address</label>
  <div class="valid-feedback">
      Looks good!
    </div>
    <div class="invalid-feedback">
      Looks no good!
    </div>
</div>
<div className="form-floating">
  <input type="password" className={`form-control ${message.type}`} id="floatingPassword validationPass" placeholder="Password" value={password}   onChange={(e)=>{setpassword(e.target.value)}}/>
  <label htmlFor="floatingPassword validationPass">Password</label>
  <div class="valid-feedback">
      Looks good!
    </div>
    <div class="invalid-feedback">
      Looks not good!
    </div>
</div>


<button className="btn btn-primary w-100 mt-3 py-2 cutsom-btn" type='submit'>Submit</button>

            </form>
        </div>
       <div className='col-6 left '>
        <div className='continer-left-login d-flex justify-content-center align-items-center '>
        <div className="sideBar-header d-flex align-items-center ">
            <div className="sidebar-brand fs-1">
             S
              <span className='logo '>
                zone
              </span>
            </div>
        </div>
        </div>
       </div>

    </div>
  )
}
