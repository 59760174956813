import { addDoc, collection, deleteDoc, doc, increment, setDoc, updateDoc } from "firebase/firestore"; 
import { db, storage } from "../schoolBackend/schoolFirebase";
import { deleteObject, getDownloadURL, listAll, ref, uploadBytesResumable } from "firebase/storage";
import { toast } from 'react-toastify';

export const options = {
  
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    
}
export const AddToProductsFun = async (data,id) => {
  try {
    const docRef = await setDoc(doc(db, "products", id), data)
    toast.success(`Success  adding ${id} !`,options );

  } catch (e) {
    console.log(e);
    toast.error(`Error adding  ${id} !`,options );
  }
  }


export const setToProductsFun = async (data,id) => {

  try {
    const docRef = await updateDoc(doc(db, "products",id), {
      ...data    
    })
    toast.success(`Success  updated ${id} !`,options );

  } catch (e) {

    toast.error(`something Erorr try again with ${id} !`,options );
  
  }

  }
  
export const  uploadImgToStorage = (file,id,setState) =>{

const uploadTask = uploadBytesResumable(ref(storage, 'products/' + id +'/'+ file.name), file);

uploadTask.on('state_changed',
  (snapshot) => {
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    setState({message:"Uplaoding",status:"Uplaoding"})

    
  }, 
  (error) => {
    setState('err')
    setState({message:"Error",status:"failed"})
    toast.error(`Error  uploaded  ${id} !`,options );
  },
  () => {
    // Upload completed successfully, now we can get the download URL
    toast.success(`Success  uploaded ${file.name} !`,options );
    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
      setState({message:downloadURL,status:"compelete"})
     
    });
  }
);

}
export const  uploadImgToStorageMultipleImg = (file,id) =>{

const uploadTask = uploadBytesResumable(ref(storage, 'products/' + id +'/'+ file.name), file);

uploadTask.on('state_changed',
  (snapshot) => {
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    return {message:"Uplaoding",status:"Uplaoding"}
    
  }, 
  (error) => {
    toast.error(`Error  uploaded  ${id} !`,options );
    return {message:"Error",status:"failed"}
  },
  () => {
    // Upload completed successfully, now we can get the download URL
    toast.success(`Success  uploaded ${file.name} !`,options );
    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
      
      return {message:downloadURL,status:"compelete"}

    });
  }
);

}



export const deleteFirestore = async (col,id) =>{



// Create a reference under which you want to list
const listRef = ref(storage, 'products/'+id);

// Find all the prefixes and items.
listAll(listRef)
  .then((res) => {
    res.prefixes.forEach((folderRef) => {

      // All the prefixes under listRef.
      // You may call listAll() recursively on them.
    });
    res.items.forEach((itemRef) => {
      // All the items under listRef.
      deleteObject(itemRef).then(async () => {
        toast.success(`Success  delete ${id} !`,options );
          // File deleted successfully
 
        }).catch((error) => {
          // Uh-oh, an error occurred!
          toast.error(`Error  delete  ${id} !`,options );
          
        
        });

    });
  }).catch((error) => {
    // Uh-oh, an error occurred!
  });

  const docref = doc(db, col,id);

  await deleteDoc(docref);


}

export const RefundAndCancelOrder = async (data,size,id) => {
let productDoc = doc(db, "products",id);

  try {
    const docRef = await updateDoc(productDoc, {
      [`${size}`] :increment(data)   
    })
    toast.success(`Success  updated ${id} !`,options );

  } catch (e) {

    toast.error(`something Erorr try again with ${id} !`,options );
  
  }

  }