import React, { useState } from "react";
import { FormAddMultiple } from "./FormAddMultiple";
import { FormOneStudent } from "./formOneStudent";
import { NavBar } from "../../../navbar/Navbar";



export const AddUsers = ({head}) => {



  return (
    <div className="add-users">
              <NavBar head={head} />

      <div className="screen">
        <div className="sereen-container"></div>
      </div>
      <div className="accordion" id="accordion">
        <div className="accordion-item">
          <div className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Add One Student
            </button>
          </div>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
            <div className="form-addOne">
        <FormOneStudent />
      </div>
            </div>
          </div>
        </div>
  
  
        <div className="accordion-item">
          <div className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              Add muliple Students
            </button>
          </div>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse "
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
            <div className="form-addMultple">
            <FormAddMultiple />
      </div>
            </div>
          </div>
        </div>
  
  
  
  
      </div>
    </div>
  );
};
