import React, { useEffect, useState } from 'react'
import { CustomeTag } from '../CustomeTag/CustomeTag'
import { TbPencilMinus } from 'react-icons/tb'
import { collection, getDocs } from 'firebase/firestore'
import { toast } from 'react-toastify'
import { db } from '../../schoolBackend/schoolFirebase'
import { Loader } from 'rsuite'
import { handelDeleteOrder } from '../../functions/deleteHandel'
import { postOrder } from '../../functions/editeFirebase'

export const EditOrder = ({order}) => {
    const [products,setproducts] = useState([])
    const [editedOrder,setEditedOrder] = useState({})
    const [looding,setlooding] = useState(true)


    useEffect(() => {
        setEditedOrder(order)
    if(looding  === true){
            let arr = [];
            getDocs(collection(db, 'products')).then((res)=>{
              res.forEach((doc,i) => {
                arr.push({...doc.data(),id: doc.id});
            });
            setproducts(arr)
            setlooding(false)
            }).catch((err)=>{ toast.error(err.message)})
         }
    }, [looding])
    
let handelChanage = (type,itemProduct,item,packitem,eventVal,quantity) =>{
    if(type === 'item'){

        if(quantity.eventVal < itemProduct){
            setEditedOrder({...editedOrder,items:{...editedOrder.items,[`${item}`]:{...itemProduct,size:eventVal,status:'request'}}})
        } else {
            setEditedOrder({...editedOrder,items:{...editedOrder.items,[`${item}`]:{...itemProduct,size:eventVal}}})
        }






       
    } else if ( type === 'package'){


        if(quantity.eventVal === 0 ){



            setEditedOrder(
                {...editedOrder,
                    items:{
                        ...editedOrder.items,
                        [`${item}`]:{...editedOrder.items[item],
                            products:{...editedOrder.items[item].products,
                                [`${packitem}`]:{
                                    ...itemProduct,size:eventVal,status:'request'
                                }
                            
                            }}}}
                )
        } else {

        setEditedOrder(
            {...editedOrder,
                items:{
                    ...editedOrder.items,
                    [`${item}`]:{...editedOrder.items[item],
                        products:{...editedOrder.items[item].products,
                            [`${packitem}`]:{
                                ...itemProduct,size:eventVal
                            }
                        
                        }}}}
            
            
            
            )
        }




    }
}


const handelEdit = () =>{
    postOrder(editedOrder)
    handelDeleteOrder(editedOrder.id,editedOrder)
    
}


let selectOptions = (type,itemProduct,item,packitem) =>{
   
    let specificProduct = products.filter(product => product.id == itemProduct.id)[0].quantity

    if(products.length > 0){
return <select class="form-select" aria-label="Default select example" onChange={(e)=>
handelChanage(type,itemProduct,item,packitem,e.target.value,specificProduct)

} value={itemProduct.size}>
    
{Object.keys(specificProduct)?.map((el,i)=>{
    return <>
      <option selected={el == itemProduct.size}  value={el}>{el}</option>
    </>
})}
</select>
    }
}
  return (
    <div className='edit '>
        {looding === true ? <Loader />:
        <>

        <div className=' bg-light w-100 ps-5 py-3 position-sticky'>
        <h5 className='edit-header text-center text-danger text-opacity-75 mb-2'>
            Editing Order <TbPencilMinus /> 
        </h5>
            <p className='text-dark fs-6 text-opacity-50'>
                Order For:
                <span className='text-dark text-opacity-100 fw-bolder ms-2'>
                {editedOrder.name}
                </span>
            </p>
            <p className='text-dark fs-6 text-opacity-50'>
                status:
                <span className='text-dark text-opacity-100 fw-bolder ms-2 '>
                    <CustomeTag type={editedOrder.status} />
                </span>
            </p>
            <p className='text-dark fs-6 text-opacity-50'>
                Date T Time:
                <span className='text-dark text-opacity-100 fw-bolder ms-2'>
                {editedOrder.created}
                </span>
            </p>
            <p className='text-dark fs-6 text-opacity-50'>
                Total cost:
                <span className='text-dark text-opacity-100 fw-bolder ms-2'>
                {editedOrder.total}
                </span>
            </p>
            <p className='text-dark fs-6 text-opacity-50'>
            <button className='btn btn-primary fw-bolder px-5' onClick={()=>{handelEdit()}} >Edit</button>
            </p>
        </div>
        <hr/>
        <div className='edit-body h-75'>
        <h6 className='text-start text-danger text-opacity-75 '>
        order Items  <TbPencilMinus /> 
        </h6>
        <form onSubmit={(e)=>{}}>
        <div className="d-flex">
            <div className="col-6">
            <h5 className=" form-control   border-0">name</h5>
            </div>
            <div className="col-3">
            <h5 className=" form-control   border-0">Size</h5>
            </div>
            <div className="col-3">
            <h5 className=" form-control   border-0">Count</h5>
            </div>
      
           </div>
        {editedOrder?Object.keys(editedOrder.items).map((el,i)=>{
         return  editedOrder.items[el].type === 'item'?
           <div >
           <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="col-6">
            <h5 className="text-primary form-control text-opacity-100   border-0">{i+1} : {editedOrder.items[el].name}</h5>

            </div>
         
            <div class=" col-3">
                {/* 
                type,itemProduct,item,packitem
                */}
            {selectOptions("item",editedOrder.items[el],el,'0')}
            </div>
            <div className="col-3">
            <h5 className="text-danger form-control text-opacity-100   border-0">
               <span className='bg-danger bg-opacity-10  px-2 py-1 rounded-3'>
               {editedOrder.items[el].count} </span> 
                
                </h5>

            </div>
      
           </div>

            </div>
           :
           <div>
             {editedOrder.items[el].type === 'package'?Object.keys(editedOrder.items[el]?.products)?.map((els,i)=>{
              return         <div >
              <div className="d-flex justify-content-between align-items-center mb-3">
               <div className="col-6">
               <h5 className="text-primary form-control text-opacity-100   border-0">{i+1} : {editedOrder.items[el]?.products[els].name}</h5>
               </div>    
               <div class=" col-3">
                                {/* 
                type,itemProduct,item,packitem
                */}
               {selectOptions("package",editedOrder.items[el].products[els],el,els)}
               </div>
               <div className="col-3">
               <h5 className="text-danger form-control text-opacity-100   border-0">
                  <span className='bg-danger bg-opacity-10  px-2 py-1 rounded-3'>
                  {editedOrder.items[el].products[els].count} </span> 
                   
                   </h5>
   
               </div>
         
              </div>
   
               </div>
             }):""}
           </div>
           
        
        }):""}
        </form>
        </div>
        </>
}
    </div>

  )
}
