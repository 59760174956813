import { serverTimestamp } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { ReportCreation } from "../ReportCreation/ReportCreation";

export const RequestReports = ({ orders }) => {
  const [data, setData] = useState([]);



  const prepdata = () => {
    let arr = [];
    if (orders ) {
      orders.forEach((order) => {
        
            Object.keys(order.items).forEach((item) => {
            if (
              order.items[item].type === "item" &&  order.items[item].status === 'request'
            ) {
              return arr.push({
                name: order.name,
                studentId: order.studentId,
                id: order.items[item].id,
                size: order.items[item].size,
                order_status: order.status,
                price:order.items[item].totalPrice,
                status:order.items[item].status
              });
            } else if (order.items[item].type === "package") {
              Object.keys(order.items[item].products).forEach((product) => {
               if(order.items[item].products[product].status === 'request') {
                  return arr.push({
                    name: order.name,
                    studentId: order.studentId,
                    id: order.items[item].products[product].id,
                    size: order.items[item].products[product].size,
                    order_status: order.status,
                    status: order.items[item].products[product].status,
                    price:order.items[item].totalPrice,
                    package:order.items[item].id
                  });
                }
              });
            }
          });
        
      });
    }
    setData(arr);
    console.log(arr)
  };

  return (
    <div className="w-100">
      ComReports
      <hr />
      <button className="btn btn-danger px-5 py-1" onClick={prepdata}>
        GO
      </button>
      <ReportCreation head="aaaaaaaa" data={data} />
    </div>
  );
};
