import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

export const allProductsSlice = createSlice({
    name:"all-products",
    initialState,
    reducers:{
        GetAllProducts: (state,action) => {
         return   state = [...action.payload]
        }
    }
})

export const { GetAllProducts  } = allProductsSlice.actions;

export default allProductsSlice.reducer;