import { addDoc, collection, doc, increment, updateDoc } from "firebase/firestore"
import { db } from "../schoolBackend/schoolFirebase"

const editeall = async (data) => {
    if(data){    
    Object.keys(data.items).forEach((el)=>{
        if(data.items[el].type === 'item'){
            updateDoc(doc(db, "products", data.items[el].id), 
        {[`quantity.${data.items[el].size}`]: increment(- data.items[el].count)}).then(()=>{
               
            })
        }
        else if(data.items[el].type === 'package'){
            Object.keys(data.items[el].products).forEach((els)=>{
                updateDoc(doc(db, "products",data.items[el].products[els].id), {[`quantity.${data.items[el].products[els].size}`]: increment(data.items[el].products[els].count)}).then(()=>{
                    
                })
            })
        }
    })
    }
  }
export const postOrder = async (data) => {
    try {
      const docRef = await addDoc(collection(db, "orders"), {
        ...data,    
      }
      ).then((data) =>{

}
      ).finally(()=>{
           editeall(data)
      })
    } catch (e) {
      return console.error("Error adding document: ", e);
    }
  
}
