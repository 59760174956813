// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { writeBatch, doc } from "firebase/firestore"; 
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfigShoolEcomerce = {
  apiKey: "AIzaSyBNzRkpvvfuYdantemy2fN3h9Y4tyYlgP0",
  authDomain: "school-ecommerce.firebaseapp.com",
  projectId: "school-ecommerce",
  storageBucket: "school-ecommerce.appspot.com",
  messagingSenderId: "247491858688",
  appId: "1:247491858688:web:352e8fee9ba324e12a23e5"
};


 const firebaseConfig = {
   apiKey: "AIzaSyDRW-3r1f7cDT7Fq80Z4tM9ieav32d6NRw",
  authDomain: "school-backend-c86c7.firebaseapp.com",
   projectId: "school-backend-c86c7",
  storageBucket: "school-backend-c86c7.appspot.com",
   messagingSenderId: "131580464169",
  appId: "1:131580464169:web:9ce83f7a6efb303216e038"
 };


// Initialize Firebase
export const app =initializeApp(firebaseConfigShoolEcomerce) ;

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);

// Create a root reference
export const storage = getStorage(app);

export const batch = writeBatch(db);
