import { deleteDoc, deleteField, doc, increment, updateDoc } from "firebase/firestore";
import { batch, db } from "../schoolBackend/schoolFirebase";
import { toast } from "react-toastify";




 export const handelDeleteItem = async (orderId,orderItemId,childId,itemId,itemSize,itemCount,unitePrice,type) =>{
    const orderRef = doc(db, "orders",orderId);
    const inventoryRef = doc(db, "products", itemId);
   
 //change delete of item in order

 if (type === 'item'){
    await updateDoc(orderRef, {
        'total':increment(- parseInt(unitePrice)),
         [`items.${orderItemId}`]:deleteField()
    });
} else if (type === 'package'){
        await updateDoc(orderRef, {
        'total':increment(- parseInt(unitePrice)),
         [`items.${orderItemId}.products.${childId}`]:deleteField()
    });
}
    // //change price Of order
    // //increase count of items for this size in inventory
    updateDoc(inventoryRef, {[`quantity.${itemSize}`]: increment(itemCount)}).then(()=>{
        toast.warning("updated")
    })


// // Commit the batch
// // const userRef = doc(db, "users", customerId);
// /*
// batch.update(sfRef, {"population": 1000000});
// batch.delete(laRef);
// */
//     //change price Of order
//     //increase count of items for this size in inventory
//     // updateDoc(inventoryRef, {[`quantity.${itemSize}`]: increment(itemCount)}).then(()=>{
//     //     toast.warning("updated")
//     // })
// // Commit the batch
 }






 export const handelDeleteOrder = async (orderId,order) =>{
    const orderRef = doc(db, "orders",orderId);
if(order){   
     deleteDoc(orderRef);
Object.keys(order.items).forEach((el)=>{
    if(order.items[el].type === 'item'){
        updateDoc(doc(db, "products", order.items[el].id), {[`quantity.${order.items[el].size}`]: increment(order.items[el].count)}).then(()=>{
           
        })
    }
    else if(order.items[el].type === 'package'){
         deleteDoc(orderRef);
        Object.keys(order.items[el].products).forEach((els)=>{
            updateDoc(doc(db, "products",order.items[el].products[els].id), {[`quantity.${order.items[el].products[els].size}`]: increment(order.items[el].products[els].count)}).then(()=>{
                
            })
        })
    }
})
toast.success('orders deleted successfully')
}

 }


 export const handelRefundItem = async (orderId,orderItemId,childId,itemId,itemSize,itemCount,unitePrice,type) =>{
        const orderRef = doc(db, "orders",orderId);
    const inventoryRef = doc(db, "products", itemId);
 //change delete of item in order
 if (type === 'item'){
    await updateDoc(orderRef, {
        'status':"partly-refunding",
        'total':increment(- parseInt(unitePrice)),
         [`items.${orderItemId}.status`]:'refunding',
         [`items.${orderItemId}.price`]:unitePrice,
    });
} else if (type === 'package'){
        await updateDoc(orderRef, {
            'status':"partly-refunding",
        'total':increment(- parseInt(unitePrice)),
         [`items.${orderItemId}.products.${childId}.status`]:'refunding',
         [`items.${orderItemId}.products.${childId}.price`]:unitePrice,
    });
}
    // //change price Of order
    // //increase count of items for this size in inventory
    updateDoc(inventoryRef, {[`quantity.${itemSize}`]: increment(itemCount)}).then(()=>{
        toast.warning("updated")
    })
// Commit the batch
// const userRef = doc(db, "users", customerId);
/*
batch.update(sfRef, {"population": 1000000});
batch.delete(laRef);
*/
    //change price Of order
    //increase count of items for this size in inventory

// Commit the batch
 }
 export const handelRefundOrder = (orderId,order) =>{
  
        const orderRef = doc(db, "orders",orderId);
if(order){    
             updateDoc(orderRef, {     
         [`status`]:'refunding'
    });
Object.keys(order.items).forEach((el)=>{
    if(order.items[el].type === 'item'){
        updateDoc(doc(db, "products", order.items[el].id), 
    {[`quantity.${order.items[el].size}`]: increment(order.items[el].count)}).then(()=>{
            toast.warning("updated")
        })
    }
    else if(order.items[el].type === 'package'){
        console.log('UP package')
        Object.keys(order.items[el].products).forEach((els)=>{
            updateDoc(doc(db, "products",order.items[el].products[els].id), {[`quantity.${order.items[el].products[els].size}`]: increment(order.items[el].products[els].count)}).then(()=>{
                toast.warning("updated")
            })
        })
    }
})
}

 }
