import React, { useEffect, useState } from "react";
import  {TbUsers}  from "react-icons/tb";



export const ReportCardUsers = ({data}) => {

    const [valuesData,setValuesData] = useState({
        all:0,
        active:0,
        ownPackage:0
    })

    useEffect(() => {
        
        if(data){

          let range = [...data]
          let activies =  range.filter(el => el.parentemail === undefined || el.parentemail === null || el.parentemail === ''  )
          let ownPackages =  range.filter(el => el.ownPackage !== 'yes')

          setValuesData({
            all:range.length,
            active:range.length - activies.length,
            ownPackage:ownPackages.length
          })
        }
    }, [data])
    


  return (
    <div className="report-card paid-reports col-3  px-2 h-100 ">
      <div className=" p-4 rounded-2 h-100" style={{background:"#ffffff"}} >
        <div className="d-flex justify-content-between align-items-center">
          <div className=" p-2  rounded-3 d-flex justify-content-center align-items-center  bg-warning bg-opacity-10 text-warning">
          <TbUsers className="fs-6" />
          </div>
          <div className="p-2  rounded-3 d-flex justify-content-center align-items-center  text-dark text-opacity-50 fw-bolder">
          </div>
        </div>
        <div className="d-flex mt-5 justify-content-between align-items-center">
          <div className=" p-2  rounded-3 d-flex flex-column  justify-content-center align-items-start ">
            <h6 className="text-opacity-25  text-dark text-start p-0 mb-3">
             Students
            </h6>
            <h6 className="text-opacity-75 text-dark">
              {" "}
              {valuesData.all} 
            </h6>
          </div>
          <div className=" p-2  rounded-3 d-flex flex-column  justify-content-center align-items-start ">
            <h6 className="text-opacity-25 text-dark text-start p-0 mb-3">
              Active
            </h6>
            <h6 className="text-opacity-75 text-dark">
              {" "}
              {valuesData.active}
            </h6>
          </div>
          <div className=" p-2  rounded-3 d-flex flex-column  justify-content-center align-items-start ">
            <h6 className="text-opacity-25 text-dark text-start p-0 mb-3">
              Own
            </h6>
            <h6 className="text-opacity-75 text-dark">
              {" "}
              {valuesData.ownPackage} 
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};
