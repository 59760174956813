import { doc, updateDoc } from "firebase/firestore";
import { db } from "../schoolBackend/schoolFirebase";
import {toast } from 'react-toastify'
import { options } from "./AddProductFun";
export const handelEdit  = async (file,data,id) => {
  
    try {
      const docRef = await updateDoc(doc(db, file , id), {
        ...data    
      })

      toast.success(`${id} updated To ${Object.values(data)[0]} successfully`,options)

    } catch (e) {
      console.log(e)
     toast.error(`${id} still ${Object.values(data)[0]}`,options)
    }
    }



    
export const handelEditCount  = async (file,id,size,count) => {

    try {
      const docRef = await updateDoc(doc(db, file , id), {
        [`quantity.${size}`] : count
      })

      toast.success(`${id} updated To ${Object.values(size)[0]} successfully`,options)

    } catch (e) {
      console.log(e)
     toast.error(`${id} still ${Object.values(size)[0]}`,options)
    }
    }