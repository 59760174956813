import React, {  useEffect, useState } from 'react'

import { NavBar } from '../navbar/Navbar'
import "./Reports.css"

import { Card, Grid, Metric, Text } from "@tremor/react";
import { DateRangePicker, Loader  } from 'rsuite';

import {TbChartPie, TbSearch, TbX} from 'react-icons/tb'
import { BSTable } from '../BSTable/BSTable';
import { collection, getDocs, onSnapshot } from 'firebase/firestore';
import { db } from '../../schoolBackend/schoolFirebase';
import { ComReports } from './ComReports';
import { RefundReports } from './RefundReports';
import { RequestReports } from './RequestReports';
import { InventoryReports } from './InventoryReports';
import { toast } from 'react-toastify';
import { UserPick } from './UserPick';
import { ReportCardPaid } from './ReportCard/ReportCardPaid';
import { ReportCardUsers } from './ReportCard/ReportCardUsers';
import { ReportCardOrders } from './ReportCard/ReportCardOrders';
import { TabFullCommercial } from './tables/TabFullCommercial';
export const Reports = ({head}) => {
  const [products,setProducts] = useState([]);
  const [users,setusers] = useState([]);
  useEffect(() => {
    let arr = [];
    let Allarr = [];
    const unsubscribe = onSnapshot(
      collection(db, 'orders'), 
      (snapshot) => {
        snapshot.docs.forEach(doc => {
          Allarr.push({...doc.data(),id:doc.id})
            if(doc.data().status === 'picked' || doc.data().status === 'paid' || doc.data().status === 'partly-picked'){
                arr.push({...doc.data(),id:doc.id})
            }
        })
        setAllData(Allarr)
        setData(arr)
        
        arr = []
      },
      (error) => {
      
      });
      const getData = () =>{
        getDocs(collection(db, 'products')).then((res)=>{
          res.forEach((doc,i )=> {
          Object.keys(doc.data().quantity).forEach((item,int)=>{
            arr.push({
              name:doc.data().name,
              id:doc.id,
              size:item,
              count:doc.data().quantity[item],
            })
          })}
          )
        setProducts(arr)
     
        }).catch((err)=>{ toast.error(err.message)})
        let arr = [];
    
      }
      getData()
      const getDataUser = () =>{
        let arr =[]
        getDocs(collection(db, 'users')).then((res)=>{
          res.forEach((doc,i )=> {
            
              arr.push({
  ...doc.data()
              })})
            
         setusers(arr)   
            })
          
       
        .catch((err)=>{ toast.error(err.message)})
     
    
      }
    




      getDataUser()









      return ()=>{unsubscribe()}







  }, [])
  const [filteredTypeOrder,setFilteredTypeOrder] = useState('all')
  const [daterane,setdaterane] = useState('')
  const [serached,setserached] = useState('')

const [data,setData] = useState([]);
const [allData,setAllData] = useState([]);




const [Results,setResults] = useState({
  total:0,
  totalUnpaid:0,
  totalPied:0,
  coutTotal:0,
  coutUnpaid:0,
  coutPied:0,
})




  return (
    <div className=''>
        <NavBar head={head}  />
        <div className="reports px-4">
        <div className="reports-cards ">
          <div className="d-flex flex-wrap h-100">
            {data ?
          <ReportCardPaid data={data} />
              :  
              <div className=" col-3 px-2  d-flex justify-content-center align-items-center "> 
                 <Loader size="lg" />
              </div>            
              }
            {users ?
          <ReportCardUsers data={users} />
              :  
              <div className=" col-3 px-2  d-flex justify-content-center align-items-center "> 
                 <Loader size="lg" />
              </div>            
              }
            {data?
          <ReportCardOrders data={data} />
              :  
              <div className=" col-3 px-2  d-flex justify-content-center align-items-center "> 
                 <Loader size="lg" />
              </div>            
              }
          </div>
        </div>
        <div className="reports-tables mt-5 px-2">
        <TabFullCommercial orders={allData}  />
        </div>

        
            <ComReports orders={allData} />
            --------------------
            <InventoryReports orders={allData} products={products} />
            --------------------
            <RefundReports orders={allData}  />
            ------------------
            <RequestReports orders={allData}  />
            -------------
            <UserPick />
        </div>

    </div>
  )
}
