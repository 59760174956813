import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { app } from "../schoolBackend/schoolFirebase";
import { getSpecificIdData } from "./GETFUN";


export const auth = getAuth(app);


export const Authentication = (email, password,setMessaging,setData) => {
  
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in 
      setMessaging({type:'is-valid',message:""})
      const user = userCredential.user;
      setData(getSpecificIdData('dashboardusers',email))
      // ...
    })
    .catch((error) => {
      const errorMessage = error.message;
      setMessaging({type:'is-invalid',message:errorMessage})
      // ..
    });
}

export const testAuthentication = async (email) => {
  
await  getAuth(auth)
.getUserByEmail(email)
.then((userRecord) => {
  // See the UserRecord reference doc for the contents of userRecord.
  console.log(`Successfully fetched user data: ${userRecord.toJSON()}`);
  return userRecord.toJSON()
})
.catch((error) => {
  console.log('Error fetching user data:', error);
  const errorMessage = error.message;
  return 'Error: ' + errorMessage
})}
