import React, { useRef, useState } from 'react'
import Papa from 'papaparse'
import { AddToProductsFun } from '../../firebase/AddProductFun';
import { AddToBundelFun } from '../../firebase/BundelsFirebase';
import { CustToast } from '../CustToast/CustToast';
import { getSpecificIdData } from '../../firebase/GETFUN';
import { toast } from 'react-toastify';
export const AddMultipleBp = ({server,child}) => {

    const [data,setData] = useState([])
    const [dataIndv,setDataIndv] = useState([])
    
    const [filez,setfilez] = useState('')
    const changeHandler = (e) =>{
            // Passing file data (event.target.files[0]) to parse using Papa.parse
            
            if(e.target){

            Papa.parse(e.target.files[0], {
              header: true,
              skipEmptyLines: true,
              complete: function (results) {
                let arrData = []
                

                  results.data.forEach((el,i) => { 
                    let arrOfSizes = Object.values(el)?.splice(Object.keys(el).indexOf('product'),Object.values(el)?.length).filter(el => el.length > 0)
                    let itemsdata = []
                    arrOfSizes?.forEach((els,i)=>getSpecificIdData(child,els).then(datas =>  itemsdata[i] = {...datas}))

                  let item = {
                      name:el.name,
                      unitePrice:el.price,
                      gender:el.gender,
                      stage:el.stage,
                      category:el.category,
                      items:itemsdata,
                      id:el.id,
                      coverImage:el.coverImage
                    }
                    arrData.push(item);
               
                })
                 setData(arrData)

                 setfilez('bundels')
              },
            });
          };}
              const ref = useRef();
             
  const reset = () => {
    ref.current.value = "";
  };
  let itemsProcessed  = 0;
    const hanelSubmit = (e) =>{
        e.preventDefault();

        data.forEach((el,i) =>

        {   
          setTimeout(() => {
        AddToBundelFun(el,server,el.id)
        itemsProcessed++;

        if(itemsProcessed === data.length) 
        {
          setData([])
          reset()
   toast.success('All Products added successfully ✅✅✅')
        }
      }, 1000 * i);
        }
          )
         setData([])
         reset()
        }

    
  return (
    <div>
    <form >
    <div>
<label htmlFor="formFileLg" className="form-label">Add CSV sheet</label>
<input  ref={ref} onChange={(e)=>changeHandler(e)}  className="form-control form-control-lg" id="formFileLg" accept=".csv" type="file" />
</div>

<buuton type='submit' className="btn btn-primary" onClick={(e)=>{hanelSubmit(e)}} >Submit</buuton>
    </form>

    <div className="table-responsive">
<table className="table table-striped table table-users fs-1 mb-0">
  <thead>
    <tr>
      <th className="sort border-top ps-3" data-sort="name">Name</th>
      <th className="sort border-top" data-sort="Price">Price</th>
      <th className="sort border-top" data-sort="gender">gender</th>
      <th className="sort border-top" data-sort="category">category</th>
      <th className="sort border-top" data-sort="stage">stage</th>
      <th className="sort border-top" data-sort="id">id</th>
      <th className="sort border-top" data-sort="coverImage">coverImage</th>
    </tr>
  </thead>
  <tbody className="list">
    {data?.map((el,index)=>{
        return  <tr>
        <td className="align-middle ps-3 name">{el.name}</td>
        <td className="align-middle Price">{el.unitePrice}</td>
        <td className="align-middle gender">{el.gender}</td>
        <th className="sort border-top" data-sort="category">{el.category}</th>
        <th className="sort border-top" data-sort="stage">{el.stage}</th>
      <th className="sort border-top" data-sort="id">{el.id}</th>
      <th className="sort border-top" data-sort="id">{el.coverImage}</th>
      </tr>
    })}
  </tbody>
</table>
</div>

</div>
  )
}