import React from 'react'
import { NavBar } from '../navbar/Navbar'
import { TbHandGrab, TbHandRock, TbHanger2, TbWaveSawTool } from 'react-icons/tb'

export const Home = () => {

  return (
    <div>
         <div className='order'>
        <NavBar  />
        <div className='w-100 h-100 d-flex flex-column justify-content-center align-items-center' >
            <h1>
                Hello 👋
            </h1>
            <br/>
            <h3>
                You can start work from your specific links
            </h3>
            <br/>
            <h6>
                Have A Nice Day
            </h6>
        </div>
        </div>
    </div>
  )
}
