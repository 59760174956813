import React, { useEffect, useState } from 'react'
import { jsonToCSV, useCSVDownloader } from 'react-papaparse';

import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../../schoolBackend/schoolFirebase';
import { toast } from 'react-toastify';
import { options } from '../../firebase/BundelsFirebase';
import { LooderC } from '../LooderC/LooderC';

export const UploadBpImages = ({server}) => {
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [filesNum, setfilesNum] = useState(0);
    const [filesCount, setfilesCount] = useState(0);
    const [selectedImages, setSelectedImages] = useState([]);
    const [images,setImg] = useState([])
    const [URLs,setURLs] = useState()
    const [data,setData] = useState([])
    const [item,setItem] = useState({})
    const [fileName,setFileName] = useState('')
    
    const { CSVDownloader, Type } = useCSVDownloader();

let arr = []
   
    const uploadFiles = (fileName,files) => {
        
        const promises = []
        setUploading(true)
        setfilesNum(files.length)
        files.map((file) => {
            const sotrageRef = ref(storage, `${server}/${fileName}/${file.name}`);
            const uploadTask = uploadBytesResumable(sotrageRef, file);
            promises.push(uploadTask)
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const prog = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgress(prog);

                },
                (error) => console.log(error),
                async () => {
                    setfilesCount(prev => prev +1)
                    await getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
                        arr.push({name:file.name,link:downloadURLs})
                    });
                }
            );
            setURLs(arr)
        })
        
        Promise.all(promises)
            .then((data) => {
                toast.success(`Success  uploaded all files!`,options );
                
                setUploading(false)
                
            })
            .then(err => {
               
            
                
            }
                )
    };

console.log(URLs)
    const handleSubmit = () => { uploadFiles(fileName,data) }
  return (
    <div>
        {!uploading?
        
    <>
    <input className='form-control' onChange={(e)=> setFileName(e.target.value)} type='text'  />

        
<input accept="image/png , image/jpeg, image/webp" onChange={(e)=> setData([...e.target.files])} type='file' multiple disabled={!fileName} />
<div className="table-responsive">
<table className="table table-striped table table-users fs--1 mb-0">
<thead>
<tr>
<th className="sort border-top ps-3" data-sort="name">Name</th>
<th className="sort border-top" data-sort="Price">link</th>
</tr>
</thead>
<tbody className="list">
{URLs?.map((el,index)=>{
return  <tr key={index}>
<td className="align-middle ps-3 name">{el.name}</td>
<td className="align-middle Price">{el.link}</td>

</tr>
})}

</tbody>
</table>
</div>
<button className='btn btn-primary' onClick={handleSubmit}>
prep
</button>
<CSVDownloader
className='btn btn-success ms-3'
type={Type.Link}
filename={fileName}
config={{
delimiter: ';',
}}
bom={true}
data={URLs?jsonToCSV(JSON.stringify(URLs)):""}
>
Download
</CSVDownloader> 
<span className='px-2 bg-info bg-opacity-25 fs-5 rounded-circle'>{filesNum}</span>
    </>
    :
    <LooderC progress={`${progress} file ${filesCount} from ${filesNum}  `} />
    }
        
    </div>
  )
}
