import React, { useEffect } from 'react'

import { Navigate, Outlet } from 'react-router'

import { useState } from 'react'


import { ToastContainer } from 'react-toastify'


export const PrivateRoute = () => {
const [auth,setAuth] = useState({})


  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('auth'));
    if (items) {
      setAuth(items.email);
    }

  }, [])
  
    const curentUser = auth

  return (

    curentUser ?
    <div className='App light'>

    <ToastContainer />
    <Outlet />
    </div>
     :
    <Navigate to={'/login'} />

  )
}
