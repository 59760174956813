import React, { useState } from 'react'
import { AllUsers } from '../usersControl/allUsers/AllUsers'
import './LayOutContainer.css'
import { SideBar } from '../SideBar/SideBar'
import { IoIosArrowDropleftCircle } from 'react-icons/io'
import { Outlet } from 'react-router'
import { NavBar } from '../navbar/Navbar'
import { ToastContainer } from 'react-toastify'
export const LayOutContainer = ({head}) => {

  const [toggled,setTogled] = useState(false)

  return (
    <div className='layout'>
<ToastContainer />
        <div className="d-flex">
            <div className={`col-2 layout-sidebar ${toggled?"toggled":""}`}>
              <SideBar />
            </div>

            <div className="col-10 outlet-zone ps-1">
              
            <div className="sidebar-toggler" onClick={()=>setTogled(prev => !prev)}>
              <IoIosArrowDropleftCircle className='open' />
            </div>
                <Outlet />
            </div>
        </div>
    </div>
  )
}
