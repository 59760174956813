import React, { useEffect, useState } from 'react'

import { Dropdown, Popover, Tag, Toggle, Whisper } from 'rsuite';
import './CustomeTag.css'
import { TbArrowBadgeDown, TbTriangleInvertedFilled } from 'react-icons/tb';
import { handelEdit } from '../../firebase/EditeFUN';
export const CustomeTag = ({type}) => {
    if (type === 'pending'){
        return (
        <Tag className={`cutomeTag ${type}`}>
            {type}
        </Tag>)
    } else if (type === 'paid'){
        return (
            <Tag className={`cutomeTag ${type}`}>
            {type}
            </Tag>)
    } else if (type === 'picked'){
        return (
            <Tag className={`cutomeTag ${type}`}>
            {type}
            </Tag>)
    } else if (type === 'confirmed'){
        return (
            <Tag className={`cutomeTag ${type}`}>
            {type}
            </Tag>)
    }
     else if (type === 'canceled'){
        return (
            <Tag className={`cutomeTag ${type}`}>
            {type}
            </Tag>)
    }else{
        return( <Tag className={`cutomeTag`}>
        {type}
        </Tag>)
    }
}
export const CustomeCommercialTag = ({type,id}) => {}


const renderMenu = ({ onClose, left, top, className,id }  ,ref) => {
    console.log(ref)
   
    const handleSelect = e => {
      onClose();
      console.log(e);
    };
let types = [
    'paid','picked'
]
const {Item , Menu} = Dropdown
    return (
      <Popover ref={ref} className={className}  style={{ left, top }} full>
        <Menu onSelect={(e)=>handleSelect(e)}>
            {types?.map((el,i)=>{
                return  <Item eventKey={el}>{el}</Item>
            })}
        </Menu>
      </Popover>
    );
  }
  
export  const ActionCell = ({ type,id }) => {
    const [checked, setChecked] = useState(true);
    const [loading, setLoading] = useState(false);
      
    useEffect(() => {
        handelEdit('orders',{'status':checked?'paid':type},id)
    }, [checked])
    
        return (
            <>
            <Toggle
            checkedChildren={
                        <Tag className={`cutomeTag  ${type}`}>
                         {type}
                    </Tag>
            } unCheckedChildren={
                <Tag className={`cutomeTag ${type}`}>
                 {type}
            </Tag>
            } />

        </>
        )
    }
