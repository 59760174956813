import React from 'react'
import './LooderC.css'
import { TbLoader, TbMoodSmile } from 'react-icons/tb'
import { Loader } from 'rsuite'
export const LooderAll = () => {
  return (
    <div className='looder d-flex justify-content-center align-items-center flex-column'>
        <h1 className='header'>Loading
        <Loader />
        
        </h1>
       
        
    </div>
  )
}
