import React from 'react'
import { deleteDoc, doc, setDoc, updateDoc } from "firebase/firestore"; 
import { db } from '../schoolBackend/schoolFirebase';
import { getAuth, createUserWithEmailAndPassword, deleteUser, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from './Authentication';
import { toast } from 'react-toastify';

// Add a new document in collection "cities"
export const options = {
  
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    
}
export const AddUserToFb = (studentData,id) => {



 setDoc(doc(db, "users", id+"@school.sys"), {...studentData})
  //updateDoc(doc(db, "users" , id+"@school.sys"),{...studentData})
//to create user in auth


}

export const DeleteUserFromDbAndAuth = (email) =>{
  //111111111@school.sys



    // ...
    
    deleteDoc(doc(db, 'users', email)).then(() => {
      // User deleted.
      
      toast.success('user Deleted successfully')
    }).catch((error) => {
console.log(error);
      // An error ocurred
      // ...
    });
  

}