import React, { useEffect, useState } from 'react'
import { AddProduct } from '../AddProduct/AddProduct'
import { AddMultipleProducts } from '../AddMultipleProducts/AddMultipleProducts'
import { UploadProductsImages } from '../UploadImages/UploadImages'
import { NavBar } from '../navbar/Navbar'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../../schoolBackend/schoolFirebase'

export const Products = ({head}) => {

  return (
    <div>
        <NavBar head={head} />

        
<div className='accordion px-3 border-0' id='products'>
        <div className='accordion-item '>
            <h2 className='accordion-header'>
                <button className='accordion-button collapsed ' type='button' data-bs-toggle='collapse'data-bs-target="#collapsethree" aria-expanded="false" aria-controls="collapsethree">
                    Upload Images
                </button>
            </h2>
            <div id="collapsethree" class="accordion-collapse collapse " data-bs-parent="#products">
      <div class="accordion-body">
            <UploadProductsImages />
      </div>
    </div>
        </div>
        <div className='accordion-item'>
            <h2 className='accordion-header'>
                <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Add Multiple Product
                </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse " data-bs-parent="#products">
      <div class="accordion-body">
            <AddMultipleProducts />
      </div>
    </div>
        </div>

        <div className='accordion-item'>
            <h2 className='accordion-header'>
                <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    Add Single Product
                </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse " data-bs-parent="#products">
      <div class="accordion-body">
            <AddProduct />
      </div>
    </div>
        </div>
    </div>
    </div>
  )
}
