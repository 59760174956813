import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { db, storage } from "../schoolBackend/schoolFirebase";
import { addDoc, collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { toast } from 'react-toastify';

// Add a new document in collection "cities"
export const options = {
  
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    
}
  
export const AddToBundelFun = async (bundel,serverName,id) => {

     try {
       const docRef = await setDoc(doc(db, serverName,id), {
      ...bundel || null
   });
   toast.success(`Success  added ${id} !`,options );
      return id
     } catch (e) {
       
       toast.error(`Error adding  ${id} !`,options );
     }
    }

  export const setToBundelFun = async (data,id,serverName) => {
    try {
      const docRef = await updateDoc(doc(db, serverName,id), {
        ...data    
      })
      toast.success('updated successfully', options)
    } catch (e) {
      toast.error(`Error edit ${serverName} ${id} !`,options );
    }
    }
  


export const  uploadImgToStorageBundel = (file,setState,setFileInfo,dataId,serverName) =>{


  
    const uploadTask = uploadBytesResumable(ref(storage, serverName + '/'+ file.name), file);
    
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setState(true)
      }, 
      (error) => {
        setFileInfo(null);
        setState(false);
        toast.error(`Error added ${file.name} !`,options );
      },
      () => {
        setState(false)
        // Upload completed successfully, now we can get the download URL
        toast.success(`Success  added ${file.name} !`,options );
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        setFileInfo(downloadURL)
        setToBundelFun({"coverImage":downloadURL},dataId,serverName)
        });
      }
    );
    
    }



export const  uploadImgToStorageSizeGuide = (file,dataId,serverName,keyName) =>{


  
    const uploadTask = uploadBytesResumable(ref(storage, serverName + '/'+ file.name), file);
    
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        
      }, 
      (error) => {

        toast.error(`Error added ${file.name} !`,options );
      },
      () => {
  
        // Upload completed successfully, now we can get the download URL
        toast.success(`Success  added ${file.name} !`,options );
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        setToBundelFun({[`${keyName}`]:downloadURL},dataId,serverName)
        });
      }
    );
    
    
    }