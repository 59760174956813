import React, { useEffect, useState } from 'react'
import './SideBar.css'
import { IoIosArrowDropleftCircle } from 'react-icons/io'
import { TbSmartHome ,TbShoppingCart, TbUsers, TbCircleSquare, TbFileText, TbReportMoney, TbShirt, TbShirtSport, TbBox, TbBoxSeam, TbPackage, TbPackageImport, TbUserPlus, TbBuildingStore, TbGitCherryPick } from 'react-icons/tb'
import { Link, useNavigate } from 'react-router-dom'
export const SideBar = () => {
  const [access,setacess] = useState()
  const [Looding,setLooding] = useState(true)
  
  const navigate = useNavigate()
    useEffect(() => {
      const items = JSON.parse(localStorage.getItem('auth'));
      if (items) {
          setacess(items.access);
          setLooding(false)
      }else {
        navigate('/login')
      }
    }, [])
    
  const data = [
    {header:"MAIN MENU",items:[
      {title:"Dashboard",icon:<TbSmartHome />,link:"/dashboard" ,access:'admin'},
      {title:"Order",icon:<TbShoppingCart />,link:"order-management",access:'finance'},
      {title:"Complete orders",icon:<TbBuildingStore />,link:"complete",access:'inventory'},
      {title:"reports orders",icon:<TbBuildingStore />,link:"/reports-management",access:'finance'},
    ]},
    {header:"commercial",items:[
      {title:"pay",icon:<TbReportMoney />,link:"pay",access:'finance'},
    ]},
    {header:"Pick up",items:[
      {title:"Picked",icon:<TbGitCherryPick/>,link:"Pick-Up",access:'inventory'},
    ]},
  
    {header:"Others",items:[
      {title:"Refund",icon:<TbGitCherryPick/>,link:"refund",access:'finance'},
      {title:"Request",icon:<TbShirt/>,link:"request",access:'inventory'},
    ]},
    {header:"Inventory",items:[
      {title:"Store",icon:<TbBuildingStore />,link:"inventory",access:'inventory'},
    ]},
    {header:"packages",items:[
      {title:"Add Products",icon:<TbShirtSport  />,link:"Add-Products",access:'admin'},
      {title:"Product List",icon:<TbShirt />,link:"Product-List",access:'admin'},
    ]},
    {header:"bundels",items:[
      {title:"Add bundels",icon:<TbBoxSeam />,link:"Add-bundels",access:'admin'},
      {title:"bundels List",icon:<TbBox />,link:"bundels-List",access:'admin'},
    ]},
    {header:"packages",items:[
      {title:"Add packages",icon:<TbPackageImport />,link:"Add-packages",access:'admin'},
      {title:"packages List",icon:<TbPackage />,link:"packages-List",access:'admin'},
    
    ]},
    {header:"users",items:[
      {title:"Add users",icon:<TbUserPlus />,link:"Add-users",access:'it'},
      {title:"users List",icon:<TbUsers />,link:"users-List",access:'it'}
    ]},
  ];

  return (
    <div className='sidebar toggled'>
        <div className="sideBar-header d-flex align-items-center">
            <div className="sidebar-brand">
              S
              <span className='logo'>
                zone
              </span>
            </div>
        </div>
        


{Looding === false ? <div className="side-bar-lists"> {data?.map((el,i)=>{
  return (
    <ul className='sidebar-list list-unstyled'>
      <h4 className='sidebar-list-header d-md-block d-sm-none d-block'>{el.header}</h4>

      {el.items?.map((item,it)=>{
        return(
          <li className={`sidebar-list-item ${access === item.access || access === 'admin'  ?"":"opacity-25 disabled"}`} >
          <Link  to={access === item.access || access === 'admin' ?item.link :"/" } className='sidebar-list-item-link w-100  d-flex align-items-baseline justify-content-start' >
          <span className='sidebar-list-item-icon'>
            {item.icon}
          </span>
          <span className='sidebar-list-item-title d-md-block d-sm-none d-block'>
          {item.title}
          </span>
          </Link>
        </li>
        )
      })}

    </ul>
  )
})}</div>:"looding"}












    </div>
  )
}
