import React, { useState } from 'react';
import Papa from 'papaparse';
import { AddUserToFb } from '../../../../firebase/AddUserToFb';
import { toast } from 'react-toastify';

export const FormAddMultiple = () => {
    const initialOneUser = {
        name: "",
        grade: "",
        gender: "male",
        email: "",
        ownPackage: false,
        show: "",
        lastCheckOut: 0,
        allPayment: [],
        wishlist: [],
    };

    const [data, setData] = useState([]);

    const changeHandler = (e) => {
        Papa.parse(e.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                setData(results.data);
            },
        });
    };

    let itemsProcessed = 0;
    const handleSubmit = (e) => {
        e.preventDefault();
        data.forEach((el, i) => {
            setTimeout(() => {
                let studentData = {
                    name: el.name,
                    stage: el.stage,
                    gender: el.gender,
                    id: el.id,
                    email: el.id + "@school.sys",
                    ownPackage: el.ownPackage,
                    show: "",
                    lastCheckOut: 0,
                    allPayment: [],
                };
                let studentDataID = el.id;
                itemsProcessed++;
                AddUserToFb(studentData, studentDataID);

                if (itemsProcessed === data.length) {
                    setData([]);
                    toast.success('All Products added successfully ✅✅✅');
                }
            }, 1000 * i);
        });
    };

    return (
        <div>
            <form>
                <div>
                    <label htmlFor="formFileLg" className="form-label">Add CSV sheet</label>
                    <input 
                        onChange={changeHandler} 
                        className="form-control form-control-lg" 
                        id="formFileLg" 
                        accept=".csv" 
                        type="file" 
                    />
                </div>
                <button 
                    type='submit' 
                    className="btn btn-primary" 
                    onClick={handleSubmit}
                >
                    Submit
                </button>
            </form>

            <div className="table-responsive">
                <table className="table table-striped table-users fs--1 mb-0">
                    <thead>
                        <tr>
                            <th className="sort border-top ps-3" data-sort="name">Name</th>
                            <th className="sort border-top" data-sort="email">Id</th>
                            <th className="sort border-top" data-sort="grade">Grade</th>
                            <th className="sort border-top" data-sort="gender">Gender</th>
                        </tr>
                    </thead>
                    <tbody className="list">
                        {data ? data.map((el, index) => (
                            <tr key={index}>
                                <td className="align-middle ps-3 name">{el.name}</td>
                                <td className="align-middle email">{el.id}</td>
                                <td className="align-middle grade">{el.stage}</td>
                                <td className="align-middle gender">{el.gender}</td>
                            </tr>
                        )) : (
                            <tr>
                                <td className="align-middle ps-3 name">Loading</td>
                                <td className="align-middle email">⚠</td>
                                <td className="align-middle grade">⚠</td>
                                <td className="align-middle gender">⚠</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
