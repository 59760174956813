import React, { useCallback, useEffect, useState } from 'react'
import { NavBar } from '../navbar/Navbar'
import { DateRangePicker  } from 'rsuite';
import './OrderManagment.css';
import {TbSearch, TbX} from 'react-icons/tb'
import { BSTable } from '../BSTable/BSTable';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../schoolBackend/schoolFirebase';
export const OrderManagment = ({head}) => {

  useEffect(() => {
    let arr = [];
    const unsubscribe = onSnapshot(
      
      collection(db, 'orders'), 
      (snapshot) => {
        snapshot.docs.forEach(doc => 
          arr.push({...doc.data(),id:doc.id})
          )
        setData(arr)
        arr = []
      },
      (error) => {
        console.log(error)
      });
      return ()=>{unsubscribe()}
  }, [])
  const [filteredTypeOrder,setFilteredTypeOrder] = useState('all')
  const [daterane,setdaterane] = useState('')
  const [serached,setserached] = useState('')
const typeOrderd = [
  {filter:'all'},
  {filter:'pending'},
  {filter:'paid'},
  {filter:'picked'},

]
const [data,setData] = useState([]);






const handelSearchedData = () =>{
  if(data.length > 0){
    if(serached.length > 0){
      return data.filter(el => el.cutsomer === serached || el.name === serached )
    } else if (serached.length === 0 ){
      return data
    }
  }
}

const filterDateRange = (dataset) =>{

  if(data.length > 0){
    if(daterane === ''){
      return dataset
    } else if (daterane.length > 0) {
      let startDate = new Date(daterane[0])
      startDate.setHours(0,0,0,0)
      let endDate =new Date(daterane[1]);

 return dataset?.filter(el => {

        let date = new Date((el.created).replace("T",""))
        return  new Date(date) >= new Date(startDate) &&  new Date(date) <= new Date(endDate)
      })
    }
  }
}


const preparedData = (rawdata) =>{
  if(filteredTypeOrder === 'all'){
    return rawdata
  } else {
    return rawdata?.filter(el => {
      return el.status === filteredTypeOrder 
    })
  }
}
const [Results,setResults] = useState({
  total:0,
  totalUnpaid:0,
  totalPied:0,
  coutTotal:0,
  coutUnpaid:0,
  coutPied:0,
})

useEffect(() => {
  const prepareResult = (datainput) =>{
    if(data.length > 0){
        //totla orders 
        let totals = datainput?.reduce((a , el) => {return el.total + a},0)
        //total pending
        let filteredpending = datainput?.filter(el => el.status === 'pending')
        let totalspending = filteredpending?.reduce((a , el) => {return el.total + a},0)
        //total paid
        let filterpaid = datainput?.filter(el => el.status === 'paid' || el.status === 'picked' )
        let totalpaid = filterpaid?.reduce((a , el) => {return el.total + a},0)
        setResults((prev) => { return {...prev,
          total:totals,
          totalUnpaid:totalspending,
          totalPied:totalpaid,
          coutTotal:datainput.length,
          coutUnpaid:filteredpending.length,
          coutPied:filterpaid.length,
        }})
    }
  }
  prepareResult(filterDateRange(data))
}, [data,daterane])


  return (
    <div className='order'>
        <NavBar head={head}  />

        <div className="d-flex flex-column flex-wrap">
        <div className="col-12 order-types-filters-bar w-100 text-center d-flex text-capitalize">
          <div className="result-cards d-flex px-5 w-100 justify-content-between align-items-start">
            <div className="col-3 bg-primary bg-opacity-10 text-primary rounded-2 shadow h-100 px-2 py-1 pb-3 ">
<h4 className="h6 text-dark result-head text-start">
  Total
</h4>
<h1 className="resultnum d-inline h3">
  {Results.total}  
</h1><span> EGP</span>
<br/>
<h4 className="h6 text-dark result-head text-start">
  count
</h4>
<h1 className="resultnum d-inline h3">
  {Results.coutTotal}  
</h1><span> order</span>

            </div>
            <div className="col-3 bg-success bg-opacity-10 text-success rounded-2 shadow h-100 px-2 py-1 pb-3">
<h4 className="h6 text-dark result-head text-start">
  cash In
</h4>
<h1 className="resultnum text-start ms-3 d-inline h3">
  {Results.totalPied}  
</h1><span> EGP</span>
<br/>
<h4 className="h6 text-dark result-head text-start">
  count
</h4>
<h1 className="resultnum d-inline h3">
  {Results.coutPied}  
</h1><span> order</span>

            </div>
            <div className="col-3 bg-danger bg-opacity-10 text-danger rounded-2 shadow h-100 px-2 py-1 pb-3">
<h4 className="h6 text-dark result-head text-start">
  unpaid
</h4>
<h1 className="resultnum d-inline h3">
  {Results.totalUnpaid || 0}  
</h1><span> EGP</span>
<br/>
<h4 className="h6 text-dark result-head text-start">
  count
</h4>
<h1 className="resultnum d-inline h3">
  {Results.coutUnpaid}  
</h1><span> order</span>

            </div>
          </div>
        </div>



    <div className="col-12 order-types-filters-bar w-100 text-center d-flex text-capitalize">
      {typeOrderd?.map((el,i)=>{
        return(
          <span key={i} onClick={(e)=>{setFilteredTypeOrder(e.target.dataset.filter)}} className={`col-md-1 col-3 order-types-filters-bar-item ${el.filter === filteredTypeOrder ? "active":""}`} data-filter={el.filter} >{el.filter}</span>
        )
      })}
    </div>
    <div className="col-12 flex-wrap order-filter-other d-flex justify-content-between align-items-center">
        <div className="col-12 col-md-3 mb-3">

   
          <div className="search-bar-container d-flex justify-content-between align-items-between">
              <div className="input-container w-100">
                <input placeholder='Search by order id' onChange={(e)=>setserached(e.target.value) }  list='seacedId' type="text" className='form-control search-input' />
                <datalist id="seacedId">
                {data?.length > 0 ? 
                data.map((el,i)=> <option value={el.cutsomer} key={i} />)
                :''}
                </datalist>
              </div>
              <div className="icon">
                {serached.length === 0 ? 
                  <span>
                  <TbSearch />
                  </span>:
                  <span className='btn' onClick={()=> setserached('')}>
                  <TbX />
                  </span>
                }
              </div>
          </div>
   
          <div className="search-bar-container d-flex justify-content-between align-items-between mt-3">
              <div className="input-container w-100">
                <input placeholder='Search by order student name'  onChange={(e)=>setserached(e.target.value) } list='seacedName' type="text" className='form-control search-input' />
                <datalist id="seacedName">
                {data?.length > 0 ? 
                data.map((el,i)=> <option value={el.name} key={i} />)
                :''}
                </datalist>
              </div>
              <div className="icon">
                {serached.length === 0 ? 
                  <span>
                  <TbSearch />
                  </span>:
                  <span className='btn' onClick={()=> setserached('')}>
                  <TbX />
                  </span>
                }
              </div>
          </div>
        </div>
        <div className="daterange-container col-12 col-md-3" id='daterange-container'>
          <DateRangePicker
          showOneCalendar
          classPrefix='lol3 '
          onClean={()=>setdaterane('')}
          style={{ right: '120px' }}
           onChange={(e)=>setdaterane(e)}
           format="yyyy-MM-dd"
           defaultCalendarValue={[new Date(), new Date()]}
           />
        </div>
    </div>
    <div className="col-12 order-table table-responsive">
<BSTable tab={{header:['name','status','total','created','items'],data:serached?handelSearchedData():filterDateRange(preparedData((data)))}} access="view" />
    </div>
    </div>
    </div>
  )
}
