import React from 'react'
import { NavBar } from '../navbar/Navbar'
import { AddBundel } from '../AddBundel/AddBundel'
import { UploadBpImages } from '../UploadImages/UploadBpImages'
import { AddMultipleProducts } from '../AddMultipleProducts/AddMultipleProducts'
import { AddMultipleBp } from '../AddMultipleBp/AddMultipleBp'

export const Packages = ({head}) => {
    
  return (
    <div>
            <div>
        <NavBar head={head} />
        <div className='accordion' id='products'>
        <div className='accordion-item'>
            <h2 className='accordion-header'>
                <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'data-bs-target="#collapsethree" aria-expanded="false" aria-controls="collapsethree">
                    Upload Images
                </button>
            </h2>
            <div id="collapsethree" class="accordion-collapse collapse " data-bs-parent="#products">
      <div class="accordion-body">
            { <UploadBpImages server={'packages'} /> }
      </div>
    </div>
        </div>
        <div className='accordion-item'>
            <h2 className='accordion-header'>
                <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Add Multiple Packages
                </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse " data-bs-parent="#products">
      <div class="accordion-body">
             <AddMultipleBp server={'packages'} child={'bundels'}/>
      </div>
    </div>
        </div>

        <div className='accordion-item'>
            <h2 className='accordion-header'>
                <button className='accordion-button collapsed' type='button' data-bs-toggle='collapse'data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    Add Single Package
                </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse " data-bs-parent="#products">
      <div class="accordion-body">
      {/* <AddBundel serverName="packages" comp={'bundels'} /> */}
      </div>
    </div>
        </div>

    </div>
    </div>
    </div>
    
  )
}
