import React, { useEffect, useState } from "react";
import { TbChartPie } from "react-icons/tb";
import { DateRangePicker } from "rsuite";
import { startOfDay, endOfDay, addDays, subDays } from 'date-fns';

export const ReportCardPaid = ({data}) => {

    const [valuesTimeRange,setValuesTimeRange] = useState([])
    const [valuesData,setValuesData] = useState({
        leftValue:0,
        rightValue:0,
    })

    useEffect(() => {
        let arr = []
        if(data){
          let range = data
            .filter(el => el.status === 'paid' || el.status === 'picked' || el.status === 'partly-picked' )
            setValuesData({
                leftValue:range.reduce((total,el)=> total + el.total,0),
                rightValue:range.length,
            })

            if(data.length > 0){
              if(valuesTimeRange == ''){
                return data
              } else if (valuesTimeRange[0] !== undefined ) {
                let startDate = new Date(valuesTimeRange[0])
                startDate.setHours(0,0,0,0)
                let endDate =new Date(valuesTimeRange[1]);
          
           let range = data?.filter(el => {
                  let date = new Date(el.payDate?.toDate())
                  return  new Date(date) >= new Date(startDate) &&  new Date(date) <= new Date(endDate)
                })
                setValuesData({
                  leftValue:range.reduce((total,el)=> total + el.total,0),
                  rightValue:range.length,
              })
              }
            }

        }
    }, [data,valuesTimeRange])
    console.log(valuesTimeRange)

  return (
    <div className="report-card paid-reports col-3 px-2 h-100">
      <div className=" p-4 rounded-2 h-100" style={{background:"#ffffff"}} >
        <div className="d-flex justify-content-between align-items-center">
          <div className=" p-2  rounded-3 d-flex justify-content-center align-items-center  bg-primary bg-opacity-10 text-primary">
          <TbChartPie className="fs-6" />
          </div>
          <div className="p-2  rounded-3 d-flex justify-content-center align-items-center  text-dark text-opacity-50 fw-bolder">

            {/* <DateRangePicker
            placeholder="All Time"
            onChange={(e)=>{setValuesTimeRange([
              startOfDay((new Date(e[0]))),
              endOfDay((new Date(e[1])))
            ])}}
            defaultCalendarValue={[new Date('2023-08-01 00:00:00'), new Date('2024-08-01 00:00:00')]}
            onClose={()=>{
              setValuesTimeRange([
                startOfDay((new Date('2023-08-01 00:00:00'))),
                endOfDay((new Date('2024-08-01 00:00:00')))
              ])
            }}
            showOneCalendar
            value={valuesTimeRange}


            /> */}
          </div>
        </div>
        <div className="d-flex mt-5 justify-content-between align-items-center">
          <div className=" p-2  rounded-3 d-flex flex-column  justify-content-center align-items-start ">
            <h6 className="text-opacity-25  text-dark text-start p-0 mb-3">
             Paid
            </h6>
            <h6 className="text-opacity-75 text-dark">
              {" "}
              {valuesData.leftValue} <span className="fs-6">EGP</span>{""}
            </h6>
          </div>
          <div className=" p-2  rounded-3 d-flex flex-column  justify-content-center align-items-start ">
            <h6 className="text-opacity-25 text-dark text-start p-0 mb-3">
              Volume
            </h6>
            <h6 className="text-opacity-75 text-dark">
              {" "}
              {valuesData.rightValue} <span className="fs-6">Order</span>{" "}
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};
