import React, { useEffect, useState } from 'react';
import { collection, deleteDoc, doc, getDocs, or, query, where } from "firebase/firestore";
import './AllUsers.css';
import { db } from '../../../schoolBackend/schoolFirebase';
import { NavBar } from '../../navbar/Navbar';
import { Checkbox, Table, Tag } from 'rsuite';
import { toast } from 'react-toastify';
import { DeleteUserFromDbAndAuth } from '../../../firebase/AddUserToFb';
import { useSelector } from 'react-redux';
import { TbSearch, TbX } from 'react-icons/tb';
import { handelEdit } from '../../../firebase/EditeFUN';

export const AllUsers = ({ head }) => {
    const [allUserData, setData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searched, setSearched] = useState('');
    const [checkedKeys, setCheckedKeys] = useState([]);

    const handleGetData = (e) => {
        e.preventDefault();
        let arr = [];
        const q = query(
            collection(db, "users"),
            or(
                where("email", "==", `${searched}@schoo.sys`),
                where("email", "==", `${searched}@school.sys`)
            )
        );
        getDocs(q).then((results) => {
            results.docs.forEach(doc => {
                arr.push({ ...doc.data(), id: doc.id });
            });
            setData(arr);
            setSearched("");
            setLoading(false);
        }).catch((err) => console.log(err));
    };

    const getData = () => {
        let arr = [];
        getDocs(collection(db, 'users')).then((res) => {
            res.forEach((doc) => {
                arr.push({ ...doc.data(), id: doc.id });
            });
            setAllData(arr);
            setLoading(false);
        }).catch((err) => {
            toast.error(err.message);
        });
    };

    const { Column, HeaderCell, Cell } = Table;
    const PackageCell = ({ rowData, dataKey, ...props }) => {
        if (rowData.ownPackage === "yes") {
            return (
                <Cell {...props}>
                    <Tag appearance='primary' color='green'>
                        Own
                    </Tag>
                </Cell>
            );
        } else if (rowData.ownPackage === "no") {
            return (
                <Cell {...props}>
                    <Tag appearance='primary' color='orange'>
                        <button className='btn btn-transparent p-0' onDoubleClick={() => {
                            handelEdit('users', { 'ownPackage': 'yes' }, rowData.id);
                        }}>
                            not Own
                        </button>
                    </Tag>
                </Cell>
            );
        }
    };

    let checked = false;
    let indeterminate = false;
    if (allData.length) {
        if (checkedKeys.length === allData.length) {
            checked = true;
        } else if (checkedKeys.length > 0 && checkedKeys.length < allData.length) {
            indeterminate = true;
        }
    }

    const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
        <Cell {...props} style={{ padding: 0 }}>
            <div style={{ lineHeight: '46px' }}>
                <Checkbox
                    value={rowData[dataKey]}
                    inline
                    onChange={onChange}
                    checked={checkedKeys.some(item => item === rowData[dataKey])}
                />
            </div>
        </Cell>
    );

    const handleCheckAll = (value, checked) => {
        const keys = checked ? allData.map(item => item.email) : [];
        setCheckedKeys(keys);
    };

    const handleCheck = (value, checked) => {
        const keys = checked ? [...checkedKeys, value] : checkedKeys.filter(item => item !== value);
        setCheckedKeys(keys);
    };

    let deleteProcess = 0;
    const handleDelete = (selectedArr) => {
        selectedArr.forEach(async (element, i) => {
            setTimeout(async () => {
                await DeleteUserFromDbAndAuth(element);
                deleteProcess++;
                if (deleteProcess === selectedArr.length) {
                    toast.success("All selected elements deleted ✅");
                    setCheckedKeys([]);
                }
            }, 1000 * i);
        });
    };

    return (
        <div className='all-users'>
            <NavBar head={head} />
            <div className='controls bg-primary bg-opacity-10 px-2 py-3'>
                <div className="col-12 mb-3">
                    <form className='d-flex' onSubmit={handleGetData}>
                        <div className="col-8 search-bar-container d-flex justify-content-between align-items-between">
                            <div className="input-container w-100">
                                <input
                                    placeholder='Search by order id'
                                    value={searched}
                                    onChange={(e) => { setSearched(e.target.value); }}
                                    type="text"
                                    className={`form-control py-3 search-input fw-bolder fs-5 ${searched.length === 9 ? 'text-success text-opacity-50' : 'text-dark text-opacity-50'}`}
                                />
                            </div>
                            <div className="icon">
                                {searched.length === 0 ?
                                    <span>
                                        <TbSearch />
                                    </span> :
                                    <span className='btn' onClick={() => {
                                        setSearched('');
                                        setData([]);
                                    }}>
                                        <TbX />
                                    </span>
                                }
                            </div>
                        </div>
                        <div className="col-4 px-3">
                            <button className={`h-100 w-100 btn-dark btn`} disabled={searched.length !== 9} type='submit'>search</button>
                        </div>
                    </form>
                </div>

                <span className='me-3'>
                    {checkedKeys?.length} / {allData?.length}
                </span>

                {allData?.length === 0 ?
                    <button className='btn btn-success bg-opacity-25 btn-sm my-0 py-0 me-5' onClick={getData}>Get Data</button> :
                    <button className='btn btn-secondary bg-opacity-25 btn-sm my-0 py-0 me-5' disabled>Get Data</button>
                }
                <span className='me-3'>
                    {checkedKeys?.length} / {allData?.length}
                </span>
                {checkedKeys?.length > 0 ?
                    <button className='btn btn-danger bg-opacity-25 btn-sm my-0 py-0' onClick={() => { handleDelete(checkedKeys); }}>Delete</button> :
                    <button className='btn btn-secondary bg-opacity-25 btn-sm my-0 py-0 me-5' disabled>Delete</button>
                }
            </div>

            <div className='all-users-table'>
                <Table
                    autoHeight={true}
                    loading={loading}
                    data={allUserData.length > 0 ? allUserData : allData}
                >
                    <Column flexGrow={1}>
                        <HeaderCell style={{ padding: 0 }}>
                            <div style={{ lineHeight: '40px' }}>
                                <Checkbox
                                    inline
                                    checked={checked}
                                    indeterminate={indeterminate}
                                    onChange={handleCheckAll}
                                />
                            </div>
                        </HeaderCell>
                        <CheckCell dataKey="email" checkedKeys={checkedKeys} onChange={handleCheck} />
                    </Column>
                    <Column flexGrow={1} sortable>
                        <HeaderCell>name</HeaderCell>
                        <Cell dataKey="name" />
                    </Column>
                    <Column flexGrow={1} sortable>
                        <HeaderCell>email</HeaderCell>
                        <Cell dataKey="email" />
                    </Column>
                    <Column flexGrow={1} sortable>
                        <HeaderCell>grade</HeaderCell>
                        <Cell dataKey="stage" />
                    </Column>
                    <Column flexGrow={1} sortable>
                        <HeaderCell>gender</HeaderCell>
                        <Cell dataKey="gender" />
                    </Column>
                    <Column flexGrow={1} sortable>
                        <HeaderCell>id</HeaderCell>
                        <Cell dataKey="id" />
                    </Column>
                    <Column flexGrow={1} sortable>
                        <HeaderCell>ownPackage</HeaderCell>
                        <PackageCell dataKey="ownPackage" />
                    </Column>
                    <Column flexGrow={1} sortable>
                        <HeaderCell>lastCheckOut</HeaderCell>
                        <Cell dataKey="lastCheckOut" />
                    </Column>
                </Table>
            </div>
        </div>
    );
};
