import React, { useEffect, useState } from 'react'
import './BSTable.css'
import { Loader } from 'rsuite';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../schoolBackend/schoolFirebase';
import { TbBookmark, TbCake, TbEye, TbMarkdown, TbPencil, TbReportMoney, TbX } from 'react-icons/tb';
import { CustomeTag } from '../CustomeTag/CustomeTag';
import { handelEdit, handelEditCount } from '../../firebase/EditeFUN';
import { AddToFinFun } from '../../firebase/financialFun';
import { toast } from 'react-toastify';


export const PBSTable = ({tab,access}) => {
  const {header,data,numIndex} = tab;
  const [looding,setLooding] = useState(true)
  const [dataTab,setData] = useState([])
  useEffect(() => {
    if(data){
    setData([...data])
    }

    setLooding(false)
  }, [data])

    const sortAlpha = (data) =>{
      data?.sort(function (a, b) {
        return a.length - b.length;
      });
    }
    const sortDate = (data) =>{
      data.sort((date1, date2) => date1 - date2);
    }
    const sortPrice = (data) =>{
      data.sort((a, b) => parseFloat(a.untiePrice) - parseFloat(b.untiePrice));
    }
const HandelSort = (type,sortingData) =>{
  if (type === 'name' || type === 'id '|| type === 'status') {sortAlpha(sortingData)}
  else if (type === 'unitePrice'){sortPrice(sortingData)}
  else if (type === 'date'){sortDate(sortingData)}
}
const handelPay = (status,id,total,customer,first) =>{
  console.log(customer)
  if(status === 'pending' && first === 'first'){
    handelEdit('orders',{'status':'paid'},id)
    AddToFinFun(
      {
        employ:"",
        money:total,
        order:id,
        timestamp:new Date(),
        type:'pay'
      })
      handelEdit('users',{'ownPackage':'yes'},customer)
      
    
  } else if (status === 'pending' && first !== 'first'){
    handelEdit('orders',{'status':'paid'},id)
    AddToFinFun(
      {
        employ:"",
        money:total,
        order:id,
        timestamp:new Date(),
        type:'pay'
      })
  }
}
const handelPick = (status,id) =>{
  if(status === 'paid'){
    handelEdit('orders',{'status':'picked'},id)
  }
}
const handelStatus = (access,status,id,total,customer,first) =>{
  if(access === 'view'){
  return  <CustomeTag type={status} />
  } else if (access === 'pay'){
  return  <span className='btn ' onDoubleClick={()=>{handelPay(status,id,total,customer,first)}}>
      <CustomeTag type={status} /> <TbReportMoney  className='text-success'/> {first}
    </span>
  }
  else if (access === 'picked'){
  return  <span className='btn ' onDoubleClick={()=>{handelPick(status,id,total)}}>
      <CustomeTag type={status} /> <TbReportMoney  className='text-success'/>
    </span>
  }
}
const [editeElement,setEditeElement] = useState(0)
const handelCountEdite = (id,size,e,count) =>{

  if(e.target.value <= count){
    e.preventDefault()
   toast.error('you don\'t have access to this')
   
  }else{
      setEditeElement({id:id,quantity:{[`${size}`]:e.target.value}})
  }

}

  return (
    <table className="table utable">
  <thead className='utable-head '>
    <tr>
    <th className='utable-head-th' scope="col">#</th>
      {header?.map((el,i)=>{
        return (
          <th className='utable-head-th' scope="col" >{el}</th>
        );
      })}



    </tr>
  </thead>
      <tbody>
    {looding?
      <tr>
 <Loader className='d-table w-100 h-auto bg-transparent' center content={'Loading'} />
    </tr>
   
  :
  <>
      {dataTab?.map((el,i)=>{return(<>
        <tr className={`${el.stock === 'out'?'table-danger':''}`}>
          <th scope="row">{el.index}</th>
          <td  className="col">{el.id}</td >
          <td  className="col">{el.name}</td >
          <td  className="col">{el.stage}</td >
          <td  className="col">{el.gender}</td >
        
          <td  className="col">{el.size}</td >
          <td  className="col-1 text-center">
            <input className='form-control py-0  border-1' type='number' min={el.count}  onChange={(e)=>{
              handelCountEdite(el.id,el.size,e, el.count)
            }}  value={
              editeElement.id === el.id && Object.keys(editeElement.quantity)[0] === el.size &&  editeElement.quantity[`${el.size}`] > el.count ?
              editeElement.quantity[`${el.size}`]
              :
              el.count
              } />
          </td >
          <td  className="">
            {editeElement.id === el.id && Object.keys(editeElement.quantity)[0] === el.size ?
            <button className='btn btn-info btn-sm bg-opacity-25'
            disabled={editeElement.id === el.id && Object.keys(editeElement.quantity)[0] === el.size ?false:true }
            onClick={()=>{
              handelEditCount('products',el.id,Object.keys(editeElement.quantity)[0],editeElement.quantity[`${el.size}`])
            }}>
            <TbPencil />
            </button>
            
            :
            <button className='btn btn-secondary btn-sm bg-opacity-25'
            disabled
            >
            <TbPencil />
            </button>         
            }

            </td >
          <td  className="">
            {editeElement.id === el.id && Object.keys(editeElement.quantity)[0] === el.size ?
            <button className='btn btn-danger btn-sm bg-opacity-25'
            disabled={editeElement.id === el.id && Object.keys(editeElement.quantity)[0] === el.size ?false:true }
            onClick={()=>{
              setEditeElement({})
            }}>
            <TbX />
            </button>
            
            :
            <button className='btn btn-danger btn-sm bg-opacity-25'
            disabled
            >
            <TbX />
            </button>         
            }

            </td >
        </tr>
        </>
      )
    })
      }
  </>
  }
  </tbody>
  </table>
  )
}
