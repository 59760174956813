import {  configureStore} from '@reduxjs/toolkit';
import allProductsSlice from './slices/allproducts';
import allPackagesSlice  from './slices/allPackages';
import  allUsersSlice  from './slices/allUsers';




export const store = configureStore({
    reducer: {
        products: allProductsSlice,
        packages: allPackagesSlice,
        users: allUsersSlice
    },
})