import download from "downloadjs";
import * as htmlToImage from 'html-to-image';


export const downloadinvoice =async (name) => {


    let node = document.getElementById('invoice')
    await htmlToImage.toPng(node)
    .then(function (dataUrl) {
      download(dataUrl, name);
    })
    .catch(function (error) {
      console.error('oops, something went wrong!', error);
    });
}
