import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router'
import { LooderC } from './LooderC/LooderC'
import { LooderAll } from './LooderC/LooderAll'

export const ProtectIt = () => {

    const [access,setacess] = useState()
    const [Looding,setLooding] = useState(true)
    
    
      useEffect(() => {
        const items = JSON.parse(localStorage.getItem('auth'));
        if (items) {
            setacess(items.access);
            setLooding(false)
        }
      }, [])
      
    
    
  
    const [auth,setAuth] = useState({})
      useEffect(() => {
    
        const items = JSON.parse(localStorage.getItem('auth'));
        if (items) {
          setAuth(items);
        }
      
      }, [])
    
    
    
    







      return (

        
            Looding ?
            <LooderAll />
            :
            access === "it" || access === "admin" ?
            <> 
            
               <Outlet />
        </>
        
             :
             <Navigate to={'/'} />
          )
}
