import React, { useEffect, useState } from 'react'
import './BSTable.css'
import { Loader } from 'rsuite';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../schoolBackend/schoolFirebase';
import { TbCake, TbDelta, TbEye, TbPencil, TbReportMoney } from 'react-icons/tb';
import { CustomeTag } from '../CustomeTag/CustomeTag';
import { handelEdit } from '../../firebase/EditeFUN';
import { AddToFinFun } from '../../firebase/financialFun';
import { ReciteTemp } from '../Inovice/Inovice';
import { downloadinvoice } from '../Inovice/downloadinvoice';
import { CustModal } from '../Modal/CustModal';
import { handelDeleteItem, handelDeleteOrder, handelRefundItem, handelRefundOrder } from '../../functions/deleteHandel';
import { toast } from 'react-toastify';


export const ReTable = ({tab,access}) => {

  const {header,data,products} = tab;
  const [looding,setLooding] = useState(true)
  const [dataTab,setData] = useState([])
  const [productsData,setproducts] = useState([])
  const [status,setStatus] = useState('')
  const [idOrder,setIdOrder] = useState({})
  useEffect(()=>{
      
    let timeOut = setTimeout(() => {
      downloadinvoice(`${idOrder.created}-${idOrder.name} `)
    if(idOrder && idOrder !== 'null' && status){
     
      
        setIdOrder('null')

    }
  }, 1000);
return ()=>{clearTimeout(timeOut)}
  },[idOrder])
  useEffect(() => {
    if(data){
    setData([...data])
    
    }

    setLooding(false)
  }, [data])


    const sortAlpha = (sortingData,type) =>{
      let arr = []
      arr = [...sortingData?.sort(function (a, b) {
        return a.type - b.type;

      })]
      setData(arr)
    }

    const sortDate = (data) =>{
      data.sort((date1, date2) => date1 - date2);
    }
    const sortPrice = (data) =>{
      data.sort((a, b) => parseFloat(a.untiePrice) - parseFloat(b.untiePrice));
    }

const HandelSort = (type,sortingData) =>{
  if (type === 'name' || type === 'id'|| type === 'status' || type === 'cutsomer') { return sortAlpha(sortingData,type)}
  else if (type === 'unitePrice'){sortPrice(sortingData)}
  else if (type === 'date'){sortDate(sortingData)}
}
const handelPay = (status,id,total,customer,first,nextStatus) =>{
  
  if(status === 'pending' && first === 'first'){
    handelEdit('orders',{'status':nextStatus},id)
    AddToFinFun(
      {
        employ:"",
        money:total,
        order:id,
        timestamp:new Date(),
        type:nextStatus
      })
      handelEdit('users',{'ownPackage':'yes'},customer)
      
    
  } else if (status === 'pending' && first !== 'first'){
    handelEdit('orders',{'status':nextStatus},id)
    // AddToFinFun(
    //   {
    //     employ:"",
    //     money:total,
    //     order:id,
    //     timestamp:new Date(),
    //     type:nextStatus
    //   })
      
  }
}
const handelPick = (status,id) =>{
  if(status === 'paid'){
    handelEdit('orders',{'status':'picked'},id)
  }
}
const handelStatus = (access,status,id,total,customer,first,order,nextStatus) =>{
  if(access === 'view'){
  return  <CustomeTag type={status} />
  } else if (access === 'pay'||access === 'refund' || access === 'admin' ){
  return  <span className='btn ' onDoubleClick={()=>{
    handelPay(status,id,total,customer,first,nextStatus)

    setIdOrder(order)
    setStatus(nextStatus)
    
    
    }}>
      <CustomeTag type={status} /> <TbReportMoney  className='text-success'/> {first}
    </span>
  }
  else if (access === 'picked' || access === 'admin'){
  return  <span className='btn ' onDoubleClick={()=>{
    handelPick(status,id,total)
    setIdOrder(order)
    setStatus('picked')
  }
    
    }>
      <CustomeTag type={status} /> <TbReportMoney  className='text-success'/>
    </span>
  }


}




const [searcInput,setserached] = useState('')
const [editeElement,setediteElement] = useState('')
const [edites,setedites] = useState(false)
const [father,setfather] = useState({})


const editeElementProduct = (el) =>{




}
const ProductsDropDown = (el) =>{
  
 return <div className="input-container w-100">
  <input placeholder={editeElement.name} value={searcInput} onChange={(e)=>{
    setserached(e.target.value)
   
    
    } } list='productsId' type="text" className='form-control search-input' />
  <datalist id="productsId">
  
  {productsData?.length > 0 ? 
  productsData.map((el,i)=> <option value={el} key={i} >{el.name}</option>)
  :''}
  </datalist>
</div>
}




const [refundTotal,setRefund] = useState(0);






  return (
    <>
    <table className="table utable pe-5">
  <thead className='utable-head '>
    <tr>
    <th className='utable-head-th' scope="col">#</th>
      {header?.map((el,i)=>{
        return (
          <th className='utable-head-th '  scope="col" >
            
            <span className='  text-start' >
            {el === 'cutsomer' ? "customer" : el}
            </span>
            </th>
        );
      })}

    </tr>
  </thead>

      <tbody>
    {looding?

      <tr>
 <Loader className='d-table   w-100 h-auto bg-transparent' center content={'Loading'} />
    </tr>
   
    
   
  :
  <>
      {dataTab?.map((el,i)=>{return(<>  
      <tr>
        
      <th scope="row col-1">{i+1}</th>
      <td  className="col">{el.name?el.name:el.cutsomer}</td >

      <td  className="col-2">
      <span className=''>
      
        {handelStatus(access,el.status,el.id,el.total,el.cutsomer?.trim(),el.first,el,'paid')}
        </span>
 

        
        
        </td >
     
      {access === 'pay' || access === 'admin' || access === 'refund'?
      <td  className="col-2">{el.total}</td >
      :
      ""
    }
      
      <td  className="col-2">{el.created}</td >
      <td  className="col-1" >
      <CustModal head={<TbDelta />}  color={"red"} body={
        <>
        <h4 className='text-dark text-opacity-50' >Are you Sure <span className='text-danger fw-bolder'>Refund</span>  this Order</h4>
        <button className='btn btn-danger mt-4 px-3'
          onDoubleClick={()=>{
            handelRefundOrder(el.id,el)
          }}
        >
          Refund
        </button>
        </>
      } />
 
      </td >  
      <td  className="col-2" >
        <span className='btn btn-outline-secondary btn-sm' data-bs-toggle="collapse" type="button" role="button" data-bs-target={`#coll${i}`} aria-expanded="false" aria-controls={`coll${i}`}>
        <TbEye  />
        </span>
      </td >  
        </tr>

        <tr>
     <td colspan='8'>
 
  <table className="table table-secondary collapse w-100 text-start " id={`coll${i}`} >

    <thead className='table-secondary '>
  <tr className='table-secondary '>
    <th className='' scope="">#</th>
    <th className='' scope="" >name</th>
    <th  scope="" >type</th>
    <th  scope="" >size</th>
    <th  scope="" >count</th>
    <th  scope="" >status</th>
    {access === 'pay' || access === 'admin' || access === 'refund'?
    <>

     <td  className="col-2">Refund</td>

    <td  className="col-2">Edit</td>
    </>
     
      :
      ""
    }
        
    </tr>
  </thead>
    {Object.keys(el.items)?.map((els, it) =>{return(
      <>

  <tbody>
  <tr className='table-secondary text-start '>
    <th className='' scope="">{it+1}</th>
    <th className='' scope="" >{el.id == editeElement.orderId ?
    
    
    ProductsDropDown(el)
    
    :el.items[els].name
    
    }</th>

    <th  scope="" >{el.items[els].type}</th>
    <th  scope="" >{el.items[els].size?el.items[els].size:""}</th>
    <th  scope="" >{el.items[els].count?el.items[els].count:""}</th>
    <th  scope="" >{el.items[els].status?el.items[els].status:""}</th>
    {access === 'pay' || access === 'admin' || access === 'refund' ?


    <> 

  
         <td  className="col-1">
      <CustModal head={<TbDelta  />}  color={"red"} body={
        <>
        <h4 className='text-dark text-opacity-50' >Are you Sure <span className='text-danger fw-bolder'>Refund</span>  this Order</h4>
        <button className='btn btn-danger bg-danger bg-opacity-75 mt-4 px-3' onDoubleClick={()=>{
          handelRefundItem(el.id,els,'',el.items[els].id,el.items[els].size,el.items[els].count,el.items[els].totalPrice,'item').then(()=>{toast.success("Good")})
        }}>
          Refund
        </button>
        </>
      } />
      </td >
         <td  className="col-2 ">
          <span className='btn btn-info p-0 px-3' onClick={()=>{
            editeElement.orderId?
            setediteElement({}):
            setediteElement({orderId:el.id,...els})
          }}> 
          <TbPencil />
          </span>
          
          
          </td >




</>

      
      :
      ""
    }
    </tr>
    <tr><td colSpan={'9'}>
      {typeof(el.items[els]?.products) === 'object'
      ?
      <table className='table table-striped table-light'>
      <thead>
        <tr>
          <th>#</th>
          <th>name</th>
          <th>size</th>
          <th>status</th>
          <th>Refund</th>
        </tr>
      </thead>
        <tbody>
{      el.items[els]?Object.keys( el.items[els].products).map((elpro,ip)=>{return(

        <tr>
          <th>{ip+1}</th>
          <td>{el.items[els].products[elpro].name}</td>
         
     
          <td>{el.items[els].products[elpro].size}</td>
          <td>{el.items[els].products[elpro].status}</td>
          <td  className="col-1">
      <CustModal head={<TbDelta  />}  color={"red"} body={
        <>
        <h4 className='text-dark text-opacity-50' >Are you Sure <span className='text-danger fw-bolder'>Refund</span>  this Item</h4>
        refund value: 
        <input className='form-control' value={refundTotal} onChange={(e)=>{setRefund(e.target.value)}} type='number' />
        <button className='btn btn-danger bg-danger bg-opacity-75 mt-4 px-3' disabled={!refundTotal} onDoubleClick={()=>{
          handelRefundItem(el.id,els,elpro,el.items[els].products[elpro].id,el.items[els].products[elpro].size,1,refundTotal,'package').then(()=>{toast.success("Good")})
        }}>
          Refund
        </button>
        </>
      } />
      </td >
        </tr>
      )}):""}
      </tbody>
 </table>

      :""}
</td>
    </tr>
  </tbody>

    </>
    )})}
  </table>
  </td>
        </tr>
        </>
      )
    })
      }
  </>
  }
  </tbody>
  </table>
  <div className=''>
  {
                idOrder.created?
                <div className='invoice-window' >
                <div class="">
                  <div class="invoice-window-body">
                    <div class="modal-body ">
                     <ReciteTemp order={{...idOrder}} status={status} />
                    </div>
                  </div>
                </div>
              </div>
              :""
}
</div>
  </>
  )
}
