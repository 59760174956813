import { doc, getDoc, increment, updateDoc } from "firebase/firestore";
import { db } from "../schoolBackend/schoolFirebase";
import { toast } from "react-toastify";

export const handelPicked = (orderId,order) =>{
    const orderRef = doc(db, "orders",orderId);


    if(order){    
                 updateDoc(orderRef, {
                    'pickedDate':new Date(),
             [`status`]:'partly-picked'
        });
    Object.keys(order.items).forEach((el)=>{
        if(order.items[el].type === 'item' && order.items[el].status === 'pending'){
            updateDoc(orderRef, {
                [`status`]:'picked',
                [`items.${[el]}.status`]:'picked',
                'pickedDate':new Date()
           }
           )
    } 
        else if(order.items[el].type === 'package'){
           
            Object.keys(order.items[el].products).forEach((els)=>{
               
                if(order.items[el].products[els].status === 'pending'){
                    updateDoc(orderRef, {
                        [`status`]:'picked',
                        [`items.${el}.products.${els}.status`]:'picked',
                        'pickedDate':new Date()
                   })
                } 
            })
        }
    })
    }
}

export const handelRequest = async (orderId,order,item,itemIndex) =>{
    const orderRef = doc(db, "orders",orderId);
    if(order){    
        if(item.type === 'item' && item.status === 'request'){
            const docRef = doc(db, "products", item.id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                    if(docSnap.data().quantity[`${item.size}`] > item.count){
                       
                    updateDoc(orderRef, {
                       [ `${itemIndex}`]:'picked',
                       'pickedDate':new Date()
                   }).then(()=>{
                    toast.success("good")
                   })

                }else {
                    toast.error('this item isn\'t available right now')
                }
              }
    } 
    }
}
export const handelRequestStatusOrder = async (orderId,order) =>{
    const orderRef = doc(db, "orders",orderId);
    let containRequset = false;
    if(order){    
        Object.keys(order.items).forEach((el)=>{
            if(order.items[el].type === 'item' && order.items[el].status === 'request'){
                containRequset = true;
        } 
            else if(order.items[el].type === 'package'){
                Object.keys(order.items[el].products).forEach((els)=>{
                    if(order.items[el].products[els].status === 'request'){
                        containRequset = true;
                    } 
                })
            }
        })
        if(containRequset === false){
            updateDoc(orderRef, {
                'status':'picked',
                'pickedDate':new Date()
            }).then(()=>{
             toast.success("good")
            })
        
        }
      
}
}