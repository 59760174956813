import React, { useEffect, useState } from 'react'
import { NavBar } from '../navbar/Navbar'

import { DateRangePicker  } from 'rsuite';

import '../OrderManagment/OrderManagment.css';

import {TbReload, TbSearch, TbX} from 'react-icons/tb'
import { BSTable } from '../BSTable/BSTable';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../schoolBackend/schoolFirebase';
export const Request = ({head}) => {
    const [relooding,setReLooding] = useState(true)

  useEffect(() => {

  

  
    let arr = [];
    const unsubscribe = onSnapshot(
      
      collection(db, 'orders'), 
      (snapshot) => {
        
        snapshot.docs.forEach(doc =>{ 
          if(doc.data().status === 'partly-picked'){
            arr.push({...doc.data(),id:doc.id})
          }
          })
        setData(arr)
        arr = []
      },
      (error) => {
        console.log(error)
      });
    
      return ()=>{unsubscribe()}
  }, [relooding])


  const [daterane,setdaterane] = useState('')
  const [serached,setserached] = useState('')


const [data,setData] = useState([]);



const handelSearchedData = () =>{
  if(data.length > 0){
    if(serached.length > 0){
      return data.filter(el => el.id === serached )
    } else if (serached.length === 0 ){
      return data
    }
  }
}



const filterDateRange = () =>{
  if(data.length > 0){
    if(daterane === ''){
      return handelSearchedData()
    } else if (daterane.length > 0) {
      
      return handelSearchedData()?.filter(el => {
        
        let date = new Date(el.created)
        return  date >= new Date(daterane[0]) && date <= new Date(daterane[1])
      })
    }
  }
}


  return (
    <div className='order'>
        <NavBar head={head}  />
    <div className="order-types-filters-bar w-100 text-center d-flex text-capitalize">
    <span  onClick={()=>{setReLooding(!relooding)}} className={`btn btn-md fs-6 order-types-filters-bar-item `} >
        <TbReload  className='fs-5' /> Reload
    </span>
    </div>
    <div className="order-filter-other d-flex justify-content-between align-items-center">
        <div className="col-12 col-md-3">
          <div className="search-bar-container d-flex justify-content-between align-items-between">
              <div className="input-container w-100">
                <input placeholder='Search by order id' value={serached} onChange={(e)=>setserached(e.target.value) } list='seacedId' type="text" className='form-control search-input' />
                <datalist id="seacedId">
                
                {data?.length > 0 ? 
                data.map((el,i)=> <option value={el.id} key={i} />)
                :''}
                </datalist>
              </div>
              <div className="icon">
                {serached.length === 0 ? 
                  <span>
                  <TbSearch />
                  </span>:
                  <span className='btn' onClick={()=> setserached('')}>
                  <TbX />
                  </span>
                }
              </div>
          </div>
        </div>
        <div className="daterange-container col-12 col-md-3" id='daterange-container'>
          <DateRangePicker
          classPrefix='lol3 '
          value={daterane}
          onClean={()=>setdaterane('')}
          style={{ right: '120px' }}
           onChange={(e)=>setdaterane(e)}
           format="yyyy-MM-dd"
           defaultCalendarValue={[new Date('2023-07-01 00:00:00'), new Date('2023-07-01 23:59:59')]}
           />
        </div>
    </div>
    <div className="order-table">
<BSTable  access="picked" role='request' tab={{header:['cutsomer','status','created','items'],data:filterDateRange(),}}  />
    </div>
    </div>
  )
}
