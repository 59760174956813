import React, { useEffect, useState } from 'react'
import { NavBar } from '../navbar/Navbar'
import { collection, deleteDoc, doc, getDocs } from 'firebase/firestore'
import { db } from '../../schoolBackend/schoolFirebase'
import { Checkbox, Table } from 'rsuite'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

export const BundelList = ({head,serverName}) => {

  const [data,setData] = useState([])
  const [looding,setLoading] = useState(true)
  const packagesStore = useSelector(state => state.packages)
  useEffect(() => {
    if(packagesStore?.length > 0) {
      setData(packagesStore)
      setLoading(false)

    }
  
  }, [packagesStore,looding])

  const getData = () =>{
    getDocs(collection(db, serverName)).then((res)=>{
      res.forEach((doc,i) => {
        arr.push({...doc.data(),id: doc.id});
    });
    setData(arr)
    setLoading(false)
    }).catch((err)=>{ toast.error(err.message)})
    let arr = [];

  }

  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();





const { Cell , HeaderCell , Column} = Table;



const [checkedKeys, setCheckedKeys] = React.useState([]);

let checked = false;
let indeterminate = false;
if(data){
  if (checkedKeys.length === data.length) {
    checked = true;
  } else if (checkedKeys.length === 0) {
    checked = false;
  } else if (checkedKeys.length > 0 && checkedKeys.length < data.length) {
    indeterminate = true;
  }
}

const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    <div style={{ lineHeight: '46px' }}>
      <Checkbox
        value={rowData[dataKey]}
        inline
        onChange={onChange}
        checked={checkedKeys.some(item => item.id === rowData[dataKey])}
      />
    </div>
  </Cell>
);

const handleCheckAll = (value, checked) => {
  const keys = checked ? data.map(item => {return {id:item.id,status:'pending'}}) : [];
  setCheckedKeys(keys);
};
const handleCheck = (value, checked) => {
  const keys = checked ? [...checkedKeys,  {id:value,status:'pending'}] : checkedKeys.filter(item => item.id !== value);
  setCheckedKeys(keys);
};

let deletedprocess = 0;
const hanedlDelete = (selectedArr) =>{
  selectedArr.forEach(async (element,i) => {

    setTimeout(async () => {
      await deleteDoc(doc(db, serverName, element.id));
      deletedprocess++;
      if(deletedprocess === selectedArr.length){
        toast.success("All selected Elements Deleted ✅")
        setCheckedKeys([])
        window.location.reload();
      }
    }, 1000 * i);
  });
}






  return (
    <div>
        <NavBar head={head} />
        <div className='controls bg-danger bg-opacity-10 px-2 py-3'>


          <div className='d-flex justify-content-between px-5'>

          <div className=''>
          <button className='btn btn-primary bg-opacity-25 btn-sm my-0 py-0' onClick={()=>{getData()}} >Get Data </button>

          </div>
          <div className=''>

          <span className='me-3'>
          {checkedKeys?.length} / {data?.length}
          </span>
          {checkedKeys?.length > 0?
              <button className='btn btn-danger bg-opacity-25 btn-sm my-0 py-0' onClick={()=>{hanedlDelete(checkedKeys)}} >Delete </button>
        :
        <button className='btn btn-secondary bg-opacity-25 btn-sm my-0 py-0' disabled >Delete </button>
        }
          </div>
          </div>


        </div>
        <div className="bundel-table">
        <Table
      autoHeight={true}
      data={data}
      sortColumn={sortColumn}
      sortType={sortType}
      loading={looding}
    >
               <Column flexGrow={1}>
             <HeaderCell style={{ padding: 0 }}>
          <div style={{ lineHeight: '40px' }}>
            <Checkbox
              inline
              checked={checked}
              indeterminate={indeterminate}
              onChange={handleCheckAll}
            />
          </div>
        </HeaderCell>
        <CheckCell dataKey="id" checkedKeys={checkedKeys} onChange={handleCheck} />
      </Column>
      <Column width={170} flexGrow={1}  fixed sortable>
        <HeaderCell>name</HeaderCell>
        <Cell dataKey="name" />
      </Column>


      <Column width={100} flexGrow={1} sortable>
        <HeaderCell>Id</HeaderCell>
        <Cell dataKey="id" />
      </Column>
      <Column width={100} flexGrow={1} sortable>
        <HeaderCell>Gender</HeaderCell>
        <Cell dataKey="gender" />
      </Column>
      <Column width={100} flexGrow={1} sortable>
        <HeaderCell>stage</HeaderCell>
        <Cell dataKey="stage" />
      </Column>
      <Column width={100} flexGrow={1} sortable>
        <HeaderCell>unitePrice</HeaderCell>
        <Cell dataKey="unitePrice" />
      </Column>
      <Column width={100} flexGrow={1}>
        <HeaderCell></HeaderCell>
        <Cell dataKey="" />
      </Column>
    </Table>
        </div>
    </div>
  )
}
