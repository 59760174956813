import { collection, getDocs, serverTimestamp } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { ReportCreation } from "../ReportCreation/ReportCreation";
import { db } from "../../schoolBackend/schoolFirebase";
import { toast } from "react-toastify";

export const InventoryReports = ({ orders , products}) => {
  const [data, setData] = useState([]);
  const [modifdata, setModifdata] = useState([]);






  const prepdata = async () => {
    let arr = [];
    if (orders ) {
   await   orders.forEach((order) => {
            Object.keys(order.items).forEach((item) => {
            if (
              order.items[item].type === "item"
            ) {
              return arr.push({
                id: order.items[item].id,
                size: order.items[item].size,
                order_status: order.status,
                count: order.items[item].count,
                status:order.items[item].status
              });
            } else if (order.items[item].type === "package") {
              Object.keys(order.items[item].products).forEach((product) => {
                  return arr.push({
                    id: order.items[item].products[product].id,
                    size: order.items[item].products[product].size,
                    count:order.items[item].products[product].count,
                    order_status: order.status,
                    status: order.items[item].products[product].status,
                  });
              });
            }
          });
      });
    }
    setData(arr);
    return arr;
  };

const preparedProducts = () =>{
  let arr = [...products];
  let prepArr = [];
  arr.forEach((product) =>{
  
      prepArr.push({
        name:product.name,
        size:product.size,
        inventory:product.count,
        paid:data.filter((order)=>{ return order.id === product.id && order.size === product.size && (order.status === 'picked' || order.status === 'paid')}).reduce((a , el) => {return el.count + a},0),
        hold:data.filter((order)=>{ return order.id === product.id && order.size === product.size && order.status === 'pending' }).reduce((a , el) => {return el.count + a},0),
        request:data.filter((order)=>{ return order.id === product.id && order.size === product.size && order.status === 'request' }).reduce((a , el) => {return el.count + a},0),
        out:data.filter((order)=>{ return order.id === product.id && order.size === product.size && (order.status === 'picked' || order.status === 'paid')}).reduce((a , el) => {return el.count + a},0)+data.filter((order)=>{ return order.id === product.id && order.size === product.size && order.status === 'pending' }).reduce((a , el) => {return el.count + a},0),
      })

  })

  setModifdata(prepArr)
  console.log(prepArr)
}

  const hanelLol = () =>{
    prepdata().then(()=>{
      preparedProducts();
    })
    

   
  }
  return (
    <div className="w-100">
      ComReports
      <hr />
      <button className="btn btn-danger px-5 py-1" onClick={hanelLol}>
        GO
      </button>
      <ReportCreation head="aaaaaaaa" data={modifdata} />
    </div>
  );
};
