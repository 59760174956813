import React, { useEffect, useState } from 'react'
import './Dashbord.css'
import { NavBar } from '../navbar/Navbar'
import { NavLink } from 'react-router-dom'
import {RiUserStarLine} from 'react-icons/ri'
import { AllUsers } from '../usersControl/allUsers/AllUsers'
import { SideBar } from '../SideBar/SideBar'
import { ReportCreation } from '../ReportCreation/ReportCreation'
import { useDispatch, useSelector } from 'react-redux'
import { LooderC } from '../LooderC/LooderC'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../../schoolBackend/schoolFirebase'
import { toast } from 'react-toastify'
import { GetAllUsers } from '../store/slices/allUsers'
export const Dashbord = () => {

  const users = useSelector(state => state.users)

const dispatch = useDispatch()

 const [userRepData,setUserRepData] = useState([])
 const [hoo,sethoo] = useState(true)
 const getData = () =>{
  getDocs(collection(db, 'users')).then((res)=>{
    res.forEach((doc,i) => {
      arr.push({...doc.data(),id: doc.id});
  });
  dispatch( GetAllUsers(arr))
  
  }).catch((err)=>{ toast.error(err.message)})
  let arr = [];
}



const handelUsersReport = () =>{

  let arr = []
  users?.map(user =>
  { 
arr.push({
email:user.email
,name:user.name
,gender:user.gender
,stage:user.stage,
})
}
    )
    setUserRepData(arr)
    sethoo(false)
 }


  return (
    <div className='w-100'>
        <NavBar head='Dashbord' />
        <div className='layout d-flex'>
          {/* <button type="button" onClick={()=> getData()}>
          GetAllUsers
          </button>
          <button type="button" onClick={()=> handelUsersReport()}>
          report
          </button>
            <div className="d-flex">

              {hoo?" <LooderC />":
             
              <ReportCreation head='all users' data={userRepData} />
            
            }
            </div> */}
      






































      
        </div>
    </div>
  )
}
