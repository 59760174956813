import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

export const allPackagesSlice = createSlice({
    name:"all-packages",
    initialState,
    reducers:{
        GetAllPackages: (state,action) => {
         return   state = [...action.payload]
        }
    }
})

export const { GetAllPackages  } = allPackagesSlice.actions;

export default allPackagesSlice.reducer;