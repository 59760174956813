import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { options } from "./AddProductFun";
import { toast } from "react-toastify";
import { db } from "../schoolBackend/schoolFirebase";

export const AddToFinFun = async (data) => {
    try {
      const docRef = await addDoc(collection(db, "financial"), data)
      return toast.success(`Success  adding ${data.money}`,options );
    } catch (e) {
      toast.error(`Error adding !`,options );
    }
    }