import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

export const allUsersSlice = createSlice({
    name:"all-users",
    initialState,
    reducers:{
        GetAllUsers: (state,action) => {
         return   state = [...action.payload]
        }
    }
})

export const { GetAllUsers  } = allUsersSlice.actions;

export default allUsersSlice.reducer;