import React, { useEffect, useState } from 'react'
import { NavBar } from '../navbar/Navbar'
import '../OrderManagment/OrderManagment.css';
import { TbSearch, TbX} from 'react-icons/tb'
import { BSTable } from '../BSTable/BSTable';
import { and, collection, getDocs, or, query, where } from 'firebase/firestore';
import { db } from '../../schoolBackend/schoolFirebase';

export const Pay = ({head}) => {

const [relooding,setReLooding] = useState([])
const [data,setData] = useState([]);
  const [daterane,setdaterane] = useState('')
  const [serached,setserached] = useState('')
const handleGetData = (e) =>{
  e.preventDefault();
  let arr = [];
 const q = query(collection(db, "orders"),and( where("status", "==", `pending`) , or( where("customer", "==", `${serached}@schoo.sys`),where("customer", "==", `${serached}@school.sys`))
)
 );
  getDocs(q).then((results) =>{
  results.docs.forEach(doc =>{ 
    arr.push({...doc.data(),id:doc.id})
  })
setData(arr)
setserached("")
}
  ).catch((err) =>console.log(err))
}


  return (
    <div className='order'>
        <NavBar head={head}  />

    <div className="order-filter-other d-flex justify-content-between align-items-center">
      
        <div className="col-12">
        <form className='d-flex ' onSubmit={(e)=>{handleGetData(e)}}>
          <div className="col-8 search-bar-container d-flex justify-content-between align-items-between">
              <div className="input-container w-100">
                <input placeholder='Search by order id' value={serached} onChange={(e)=>{setserached(e.target.value)} } type="text" className={`form-control py-3 search-input  fw-bolder fs-5 ${serached.length === 9?'text-success text-opacity-50':'text-dark text-opacity-50'}`} />

              </div>
              <div className="icon">
                {serached.length === 0 ? 
                  <span>
                  <TbSearch />
                  </span>:
                  <span className='btn' onClick={()=> {
                    setserached('')
                    setData([])}
                    }>
                  <TbX />
                  </span>
                }
              </div>
              
          </div>
          <div className="col-4 px-3">
          <button className={`h-100 w-100 btn-dark btn`} disabled={serached.length !== 9} type='submit'>search</button>
          </div>
        
        </form>
        </div>


    </div>
    <div className="order-table px-4">
<BSTable  access="pay" tab={{header:['customer','status','total','created','Delete','items'],data:data}}  />
    </div>
    </div>
  )
}
