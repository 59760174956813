import React from 'react'
import './LooderC.css'
import { TbLoader, TbMoodSmile } from 'react-icons/tb'
export const LooderC = ({progress}) => {
  return (
    <div className='looder d-flex justify-content-center align-items-center flex-column'>
        <h1 className='header'>Uploading Images <TbLoader /></h1>
        <p className='parg'>Please Don't reload or close this page until uploading finish <TbMoodSmile /></p>
        <p className='parg'>progress: {progress}</p>
    </div>
  )
}
