import React, { useEffect, useState } from 'react'
import { NavBar } from '../navbar/Navbar'
import { Button, ButtonGroup, Checkbox, IconButton, Table, Tag } from 'rsuite'
import { collection, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { db } from '../../schoolBackend/schoolFirebase';
import { CustModal } from '../Modal/CustModal';
import { TbEditCircle, TbPeace, TbPencil, TbPhoneOutgoing, TbPhoto, TbPlus, TbX } from 'react-icons/tb';
import { deleteFirestore, setToProductsFun } from '../../firebase/AddProductFun';

import { toast } from 'react-toastify';
import { uploadImgToStorageSizeGuide } from '../../firebase/BundelsFirebase';
const { Column, HeaderCell, Cell } = Table;




 const BodyElement = (data) =>{
  
  const [editable,setEditable] = useState(false)

  const [intialData,setInitialData] = useState(data);


  const handelSize = (e,i,element) =>{
    const prevQuantity = {...intialData.quantity};

//{md:3,sm:4,lg:5}
    if(element === 'size'){
        if(prevQuantity){

prevQuantity[`${e}`] = prevQuantity[`${i}`];
delete prevQuantity[`${i}`];
      
        }
    }else if (element === 'count'){

      Object.assign(prevQuantity,
        {[i]:e}
        )
      
    }
    setInitialData(prev => {return {...prev,quantity:prevQuantity}})
}
const handelUpload = (e,id,servername,keysProduct) =>{

  uploadImgToStorageSizeGuide(e,intialData.id,'products','sizeGuide')
  uploadImgToStorageSizeGuide(e,id,servername,keysProduct)
}
const handeldeletehandelSize = (i) =>{
    const prevQuantity = {...intialData.quantity};
  delete prevQuantity[`${i}`]
  setInitialData(prev => {return {...prev,quantity:prevQuantity}})
}
const handelAddhandelSize = () =>{
const preaQuantity = {...intialData.quantity,size:"",size1:0};
setInitialData(prev => {return {...prev,quantity:preaQuantity}})
}

  const read = () => {
    return  <>
      <IconButton className='ps-2' onClick={()=>setEditable(!editable)} appearance='primary' color='yellow' icon={<TbEditCircle className='me-2' />}> Edit</IconButton>


<div className="images-show">
      <Tag apearance='primary' color='orange'>
           images
          </Tag>
        <div className="d-flex">
          {data.images?.map((image,i) =>{ return(
            <div className="col-3" style={{overflow:'hidden'}}>
              image {i+1} : {image.length > 0?<TbPhoto />:""}
            </div>
          )})}
        </div>
      </div>

      <div className="detail-product">
        <h3>name: {data.name}</h3>
        <h3 className='h5'>id: {data.id}</h3>
        <h3 className='h5'>price: {data.unitePrice}</h3>
      </div>

      <div className="quantity w-100">
      <div className="d-flex my-2">
        <Tag className='me-4'>Size</Tag>
        <Tag>Count</Tag>
        
      </div>
         <div className="d-flex flex-column">
      
    {data.quantity?Object.keys(data.quantity).map((el,i) =>{ return(
      <div className=" d-flex">
        <Tag  className="col-6">{el}</Tag>
        <Tag className="col-6">{data.quantity[el]}</Tag>
      </div>
    )}):""}
  </div> 
  </div> 

  </>
  }
  




const hanelUpdate = () =>{
  setToProductsFun(intialData,intialData.id);
  setEditable(false)

}

  const write = () =>{
    return     <>
          <IconButton className='ps-2 me-5' onClick={()=>setEditable(!editable)} appearance='primary' color='yellow' icon={<TbEditCircle className='me-2' />}> Edit</IconButton>

          <Button className='ps-2 me-3 ' onClick={()=> hanelUpdate() } appearance="primary" color="blue" >submit</Button>

    <div className="images-show">
      <Tag appearance='primary' color='orange'>
           images
          </Tag>
        <div className="d-flex">
          {data.images?.map((image,i) =>{ return(
            <div className="col-3" style={{overflow:'hidden'}}>
              image {i+1} :        <div className="">
        {image.length > 0?<TbPhoto />:
        <input className='form-control form-control-sm'  type='file' onChange={(e)=>{
          handelUpload(e.target.files[0],intialData.id,'products',`images/${i}`)

        }}/>  
        }
       </div>
            </div>
          )})}
        </div>
      </div>
      <div className="detail-product">
        <h3>name: {data.name}</h3>
        <h3 className='h5'>id: {data.id}</h3>
        <h3 className='h5'>price: {data.unitePrice}</h3>
      </div>
    <div className="d-flex flex-column w-100">
       <div className="">
        size Guide: {intialData.sizeGuide}
        <input className='form-control' type='file' onChange={(e)=>{
          handelUpload(e.target.files[0],intialData.id,'products','sizeGuide')
        }}/>  
       </div>
    {Object.keys(intialData.quantity)?.map((quantity,i) =>{ return(
      
      <div className="d-flex flex-wrap my-2">
        <div className="col-2">  <input className='form-control ' type='text' value={quantity}  onChange={(e)=>handelSize(e.target.value,quantity,'size')}/>
</div>
        <div className="col-2"> <input className='form-control  ' type='number' min={0} value={intialData.quantity[quantity]} data-key={quantity} onChange={(e)=>handelSize(e.target.value,quantity,'count')}/>
</div>
        <div className="col-2"> <IconButton className=' ' onClick={()=> handeldeletehandelSize(quantity)} appearance="ghost" color="red" icon={<TbX className='' />} />
</div>
      </div>
    )})}
         <IconButton className='mt-4  ' onClick={()=> handelAddhandelSize() } appearance="primary" color="orange" icon={<TbPlus />}>Add</IconButton>
      
  </div>

   </>
}


  return(
    <div>
         {
    editable === true?
    write():
  read()
    }
     
      

    </div>
  )
}

  const ActionCell = ({ type, rowData, dataKey, onClick, ...props }) => {
    const DeleteElement = (rowData) =>{
    return  <>
      <IconButton onClick={()=> deleteFirestore('products',rowData.id,rowData.sizeguid,rowData.images) } appearance='primary' color='red' icon={<TbX />}/>
    </>
    }



   const Element = () =>  {
      if(type === 'edite'){

return <CustModal body={BodyElement(rowData)} />

      }else if(type === 'delete'){


        return  DeleteElement(rowData)
      }
      }
    
    return (
      <Cell {...props} style={{ padding: '6px' }}>
        {/* <Button
          appearance="link"
          onClick={() => {
            onClick(rowData.id);
          }}
        >
          {rowData.status === 'EDIT' ? 'Save' : 'Edit'}
        </Button> */}
{Element()}
      </Cell>
    );
  };
  

export const ProductsList = ({head}) => {

    const [data, setData] = useState([]);
    const [loodingproducts,setloodingproducts] = useState(true)

    const getData = () =>{
      getDocs(collection(db, 'products')).then((res)=>{
        res.forEach((doc,i) => {
          arr.push({...doc.data(),id: doc.id});
      });
      setData(arr)
      setloodingproducts(false)
      }).catch((err)=>{ toast.error(err.message)})
      let arr = [];
  
    }


const [checkedKeys, setCheckedKeys] = React.useState([]);

let checked = false;
let indeterminate = false;
if(data){
  if (checkedKeys.length === data.length) {
    checked = true;
  } else if (checkedKeys.length === 0) {
    checked = false;
  } else if (checkedKeys.length > 0 && checkedKeys.length < data.length) {
    indeterminate = true;
  }
}

const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    <div style={{ lineHeight: '46px' }}>
      <Checkbox
        value={rowData[dataKey]}
        inline
        onChange={onChange}
        checked={checkedKeys.some(item => item.id === rowData[dataKey])}
      />
    </div>
  </Cell>
);

const handleCheckAll = (value, checked) => {
  const keys = checked ? data.map(item => {return {id:item.id,status:'pending'}}) : [];
  setCheckedKeys(keys);
};
const handleCheck = (value, checked) => {
  const keys = checked ? [...checkedKeys,  {id:value,status:'pending'}] : checkedKeys.filter(item => item.id !== value);
  setCheckedKeys(keys);
};

let deletedprocess = 0;
const hanedlDelete = (selectedArr) =>{
  selectedArr.forEach(async (element,i) => {

    setTimeout(async () => {
      await deleteDoc(doc(db, "products", element.id));
      deletedprocess++;
      if(deletedprocess === selectedArr.length){
        toast.success("All selected Elements Deleted ✅")
        setCheckedKeys([])
        window.location.reload();
      }
    }, 1000 * i);
  });
}

  return (
    <div>
        <NavBar head={head} />

        <div className='controls bg-info bg-opacity-10 px-2 py-3'>
        <div className='d-flex justify-content-between px-5'>

<div className=''>
<button className='btn btn-primary bg-opacity-25 btn-sm my-0 py-0' onClick={()=>{getData()}} >Get Data </button>

</div>
<div className=''>

<span className='me-3'>
{checkedKeys?.length} / {data?.length}
</span>
{checkedKeys?.length > 0?
    <button className='btn btn-danger bg-opacity-25 btn-sm my-0 py-0' onClick={()=>{hanedlDelete(checkedKeys)}} >Delete </button>
:
<button className='btn btn-secondary bg-opacity-25 btn-sm my-0 py-0' disabled >Delete </button>
}
</div>
</div>
        </div>
    <div className="products-table">

        <Table 
        autoHeight={true}
        data={data}
        loading={loodingproducts}
        houldUpdateScroll={false}
        
        >
          <Column flexGrow={1}>
             <HeaderCell style={{ padding: 0 }}>
          <div style={{ lineHeight: '40px' }}>
            <Checkbox
              inline
              checked={checked}
              indeterminate={indeterminate}
              onChange={handleCheckAll}
            />
          </div>
        </HeaderCell>
        <CheckCell dataKey="id" checkedKeys={checkedKeys} onChange={handleCheck} />
      </Column>
        <Column flexGrow={1}>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="name"  />
        </Column>
  
        <Column flexGrow={1}>
          <HeaderCell>unitePrice</HeaderCell>
          <Cell dataKey="unitePrice" />
        </Column>
  
        <Column flexGrow={1}>
          <HeaderCell>id</HeaderCell>
          <Cell dataKey="id" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>stage</HeaderCell>
          <Cell dataKey="stage" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>gender</HeaderCell>
          <Cell dataKey="gender" />
        </Column>
        <Column flexGrow={1}>
        <HeaderCell>
          <TbPencil className='fs-5' />
        </HeaderCell>
          <ActionCell  type='edite' />
        </Column>
        <Column flexGrow={1}>
        <HeaderCell>
          <TbX className='fs-5' />
        </HeaderCell>
          <ActionCell type='delete' />
        </Column>
      </Table>
    </div>
    </div>
  )
}
