import { serverTimestamp } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { ReportCreation } from "../ReportCreation/ReportCreation";

export const RefundReports = ({ orders }) => {
  const [data, setData] = useState([]);


    useEffect(() => {

        const prepdata = () => {
            let arr = [];
            if (orders ) {
              orders.forEach((order) => {
                if (order.status === 'refunding') {
                    Object.keys(order.items).forEach((item) => {
                    if (
                      order.items[item].type === "item"
                    ) {
                      return arr.push({
                        name: order.name,
                        studentId: order.studentId,
                        id: order.items[item].id,
                        size: order.items[item].size,
                        status: order.status,
                        price:order.items[item].totalPrice,
                      });
                    } else if (order.items[item].type === "package") {
                      Object.keys(order.items[item].products).forEach((product) => {
                       
                          return arr.push({
                            name: order.name,
                            studentId: order.studentId,
                            id: order.items[item].products[product].id,
                            size: order.items[item].products[product].size,
                            status: order.status,
                            price:order.items[item].totalPrice,
                            package:order.items[item].id
                          });
                        
                      });
                    }
                  });
                } else {
                    Object.keys(order.items).forEach((item) => {
                        if (
                          order.items[item].type === "item" &&
                          order.items[item].status === "refunding"
                        ) {
                          return arr.push({
                            name: order.name,
                            studentId: order.studentId,
                            id: order.items[item].id,
                            size: order.items[item].size,
                            status: order.items[item].status,
                            price:order.items[item].totalPrice,
                          });
                        } else if (order.items[item].type === "package") {
                          Object.keys(order.items[item].products).forEach((product) => {
                            if (
                              order.items[item].products[product].status === "refunding"
                            ) {
                              return arr.push({
                                name: order.name,
                                studentId: order.studentId,
                                id: order.items[item].products[product].id,
                                size: order.items[item].products[product].size,
                                status: order.items[item].products[product].status,
                                price:order.items[item].products[product].price ,
                                package:order.items[item].id
                                                  });
                            }
                          });
                        }
                      });
                }
              });
            }
            setData(arr);
            
          };

          prepdata()
    }, [orders])
    







console.log(data)



  return (
    <div className="w-100">
      ComReports
      <hr />
      <button className="btn btn-danger px-5 py-1" >
        GO
      </button>
   
          <ReportCreation head="aaaaaaaa" data={data} />
 
  
    </div>
  );
};
