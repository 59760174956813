import React, { useEffect, useState } from "react";
import { NavBar } from "../navbar/Navbar";
import {
  Button,
  Col,
  Form,
  Grid,
  IconButton,
  InputNumber,
  Row,
  SelectPicker,
  Tag,
} from "rsuite";
import { TbMan, TbPlus, TbWoman, TbX } from "react-icons/tb";
import { Steps, Panel, ButtonGroup } from "rsuite";
import {
  AddToProductsFun,
  setToProductsFun,
} from "../../firebase/AddProductFun";
import { CustUploader } from "./Uploader";
export const AddProduct = ({ head }) => {
  const [sizeguide, setSizeGuide] = useState();
  const Genderdata = [
    {
      value: "male",
      label: (
        <>
          <TbMan /> Male{" "}
        </>
      ),
    },
    {
      value: "female",
      label: (
        <>
          <TbWoman /> female{" "}
        </>
      ),
    },
  ]?.map((item) => ({ label: item.label, value: item.value }));
  const Menu = [{ grade: "KG" }, { grade: "Prime" }, { grade: "Prep" }];
  const Stagedata = Menu?.map((item) => ({
    label: item.grade,
    value: item.grade,
  }));

  const [product, setProduct] = useState({
    quantity: [{ size: "", count: 0 }],
  });
  const [productId, setProductId] = useState(null);
  const { Group, ControlLabel, Control, HelpText } = Form;

  const handelSubmit = () => {
    if (step === 0 && product.id) {
      let modifiedProduct = {
        ...product,
        quantity: [...product.quantity].reduce(
          (a, v) => ({ ...a, [v.size]: parseInt(v.count) }),
          {}
        ),
      };
      
      AddToProductsFun(modifiedProduct,product.id,setProductId)
    }
  };

  const handelSize = (e, i, element) => {
    const prevQuantity = [...product.quantity];
    if (element === "size") {
      if (prevQuantity[i]) {
        prevQuantity[i].size = e;
      } else if (!prevQuantity[i]) {
        prevQuantity.splice(i, 0, { size: e });
      }
    } else if (element === "count" && prevQuantity[i].size.length > 0) {
      prevQuantity[i].count = e;
    }

    setProduct((prev) => {
      return { ...prev, quantity: prevQuantity };
    });
  };
  const handeldeletehandelSize = (i) => {
    const prevQuantity = [...product.quantity];
    const newaa = prevQuantity.toSpliced(i, 1);
    setProduct((prev) => {
      return { ...prev, quantity: newaa };
    });
  };
  const handelAddhandelSize = () => {
    const preaQuantity = [...product.quantity, { size: "", count: 0 }];
    setProduct((prev) => {
      return { ...prev, quantity: preaQuantity };
    });
  };
  const firstStep = () => {
    return (
      <Form onSubmit={handelSubmit}>
        <Group controlId="name">
          <ControlLabel>name</ControlLabel>
          <Control
            required
            onChange={(e) =>
              setProduct((prev) => {
                return { ...prev, name: e };
              })
            }
            name="name"
          />
          <HelpText>name is required</HelpText>
        </Group>
        <Group controlId="unitePrice">
          <ControlLabel>Price</ControlLabel>
          <Control
            required
            name="unitePrice"
            onChange={(e) =>
              setProduct((prev) => {
                return { ...prev, unitePrice: parseInt(e) };
              })
            }
            type="number"
            min={0}
          />
          <HelpText>Price is required</HelpText>
        </Group>
        <Group controlId="id">
          <ControlLabel>id</ControlLabel>
          <Control
            required
            name="id"
            onChange={(e) =>
              setProduct((prev) => {
                return { ...prev, id: e };
              })
            }
          />
          <HelpText>id is required</HelpText>
        </Group>
        <Group controlId="category">
          <ControlLabel>category</ControlLabel>
          <Control
            name="category"
            onChange={(e) =>
              setProduct((prev) => {
                return { ...prev, category: e };
              })
            }
          />
          <HelpText>category is required</HelpText>
        </Group>
        <Group controlId="gender">
          <ControlLabel>male / female</ControlLabel>
          <SelectPicker
            required
            data={Genderdata}
            style={{ width: 224 }}
            name="gender"
            onChange={(e) =>
              setProduct((prev) => {
                return { ...prev, gender: e };
              })
            }
          />
          <HelpText>gender is required</HelpText>
        </Group>
        <Group controlId="stage">
          <ControlLabel>stage</ControlLabel>
          <SelectPicker
            required
            data={Stagedata}
            style={{ width: 224 }}
            name="stage"
            onChange={(e) =>
              setProduct((prev) => {
                return { ...prev, stage: e };
              })
            }
          />
          <HelpText>stage is required</HelpText>
        </Group>

        <Grid className="ms-0">
          <h5 className="mb-2">Quantity:</h5>
          {product.quantity
            ? product.quantity.map((el, i) => {
                return (
                  <Row>
                    <Col>
                      <Group controlId={"size" + i}>
                        <ControlLabel>size</ControlLabel>
                        <Control
                          value={el.size}
                          onChange={(e) => handelSize(e, i, "size")}
                          name="size"
                        />
                      </Group>
                    </Col>
                    <Col>
                      <ControlLabel>Count</ControlLabel>
                      <div style={{ width: 160 }}>
                        <InputNumber
                          value={product.quantity[i].count}
                          defaultValue={0}
                          max={100}
                          min={0}
                          disabled={!product.quantity[i].size}
                          onChange={(e) => handelSize(e, i, "count")}
                          name="Count"
                        />
                      </div>
                    </Col>
                    <Col>
                      <IconButton
                        className="mt-4 "
                        onClick={() => handeldeletehandelSize(i)}
                        appearance="ghost"
                        color="red"
                        icon={<TbX />}
                      ></IconButton>
                    </Col>
                  </Row>
                );
              })
            : "no "}
          <IconButton
            className="mt-4 "
            onClick={() => handelAddhandelSize()}
            appearance="primary"
            color="orange"
            icon={<TbPlus />}
          >
            Add
          </IconButton>
        </Grid>
        <Group></Group>
        <Button type="submit" appearance="primary" color="green">
          submit
        </Button>
      </Form>
    );
  };

  const [ImgArr, setImgArr] = useState([]);
  const [updated, setUpdated] = useState(false);

  const secondStep = () => {
    const handelSub = () => {
      setToProductsFun(
        { images: ImgArr, sizeGuide: sizeguide },
        productId,
        setUpdated
      );
      setProductId(null);
      setImgArr([]);
      setProduct({ quantity: [{ size: "", count: 0 }] });
      setStep(0);
    };

    return (
      <>
        {updated ? (
          <Tag appearance="primary" color="orange">
            done
          </Tag>
        ) : (
          ""
        )}
        product Images:
        <div className="uploader d-flex flex-row flex-wrap">
          <CustUploader type="productImg" produstId={productId} arr={ImgArr} />
          <CustUploader type="productImg" produstId={productId} arr={ImgArr} />
          <CustUploader type="productImg" produstId={productId} arr={ImgArr} />
          <CustUploader type="productImg" produstId={productId} arr={ImgArr} />
        </div>
        <div className="size-Guide">
          size-Guide:
          <CustUploader
            type="sizeGuide"
            setSizeGuide={setSizeGuide}
            produstId={productId}
          />
        </div>
        <Button
          appearance="primary"
          color="orange"
          className=""
          onClick={() => handelSub()}
        >
          Upload images
        </Button>
      </>
    );
  };
  const [step, setStep] = useState(0);
  const onChange = (nextStep) => {
    setStep(nextStep < 0 ? 0 : nextStep > 1 ? 1 : nextStep);
  };
  const stepsArr = [firstStep(), secondStep()];
  const onNext = () => {
    onChange(step + 1);
  };

  useEffect(() => {
    if (step === 0 && productId) {
      onNext();
    }
  }, [productId, step]);

  const onPrevious = () => onChange(step - 1);
  return (
    
    
      <div className="form-addProducts">
        <div>
          <Steps current={step}>
            <Steps.Item title="creation" description="Create the product" />
            <Steps.Item title="Uploading" description="uploading images" />
          </Steps>
          <hr />
          <Panel>{stepsArr[step]}</Panel>
          <hr />
          <ButtonGroup>
            <Button onClick={onPrevious} disabled={step === 0}>
              Previous
            </Button>
            <Button
              className={step === 0 ? "d-none" : "d-static"}
              onClick={() => onNext()}
              disabled={step === 3}
            >
              Next
            </Button>
          </ButtonGroup>
        </div>
      </div>
 
  );
};
