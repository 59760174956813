import React, { useCallback, useEffect, useState } from 'react'
import { NavBar } from '../navbar/Navbar'
import { DateRangePicker  } from 'rsuite';
import '../OrderManagment/OrderManagment.css';
import {TbSearch, TbX} from 'react-icons/tb'
import { BSTable } from '../BSTable/BSTable';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../schoolBackend/schoolFirebase';
import { PBSTable } from '../BSTable/PBSTable';
export const Inventory = ({head}) => {
  useEffect(() => {
    let arr = [];
    const unsubscribe = onSnapshot(
      
      collection(db, 'products'), 
      (snapshot) => {
        snapshot.docs.forEach((doc , i )=> 
          Object.keys(doc.data().quantity).forEach((item,int)=>{
            arr.push({
              index:(i+1) +"-"+(int+1),
              name:doc.data().name,
              gender:doc.data().gender,
              stage:doc.data().stage,
              unitePrice:doc.data().unitePrice,
              id:doc.id,
              size:item,
              count:doc.data().quantity[item],
              stock:doc.data().quantity[item] === '0'? 'out' :'stock'
            })
          })
          )
        setData(arr)
        arr = []
      },
      (error) => {
        console.log(error)
      });
      return ()=>{unsubscribe()}
  }, [])


const [dataOrder,setDataOrders] = useState([])


  useEffect(() => {
    let arr = [];
    const unsubscribe = onSnapshot(
      
      collection(db, 'orders'), 
      (snapshot) => {
      
        snapshot.docs.forEach(doc => 
          arr.push({...doc.data(),id:doc.id})
          
          )
        setDataOrders(arr)
        arr = []
      },
      (error) => {
        console.log(error)
      });
      return ()=>{unsubscribe()}
  }, [])

  const [filteredTypeOrder,setFilteredTypeOrder] = useState('all')
  const [filteredStageOrder,setFilteredStageOrder] = useState('all')
  const [filteredGenderOrder,setFilteredGenderOrder] = useState('all')
  const [serached,setserached] = useState('')
const typeOrderd = [
  {filter:'all'},
  {filter:'stock'},
  {filter:'out'},
]
const StageOrder = [

  {filter:'all'},
  {filter:'KG'},
  {filter:'Prime'},
  {filter:'Prep'},
]
const GenderOrder = [
  {filter:'all'},
  {filter:'male'},
  {filter:'female'},

]
const [data,setData] = useState([]);
const handelSearchedData = () =>{
  if(data.length > 0){
    if(serached.length > 0){
      return data.filter(el => el.id === serached )
    } else if (serached.length === 0 ){
      return data
    }
  }
}
const [shownum,setShownum] = useState(10);
const [indexshow,setindexshow] = useState(0);

const paginationData = (rawData) =>{
  let modif = [...rawData]
  let showingData = modif.splice(((indexshow * shownum) +0),shownum);
  return showingData
}

const preparedStageData = (rawdata) =>{
  if(filteredStageOrder === 'all'){
    return rawdata
  } else {
    return rawdata?.filter(el => {
      return el.stage === filteredStageOrder 
    })
  } 
}
const preparedData = (rawdata) =>{
  if(filteredTypeOrder === 'all'){
    return rawdata
  }
   else {
    return rawdata?.filter(el => {
      return el.stock === filteredTypeOrder 
    })
  } 
}
const preparedGenderData = (rawdata) =>{
  if(filteredGenderOrder === 'all'){
    return rawdata
  } else {
    return rawdata?.filter(el => {
      return el.gender === filteredGenderOrder 
    })
  } 
}
const [Results,setResults] = useState({
  total:0,
  totalUnpaied:0,
  totalPied:0,
  coutTotal:0,
  coutUnpaied:0,
  coutPied:0,
})

 useEffect(() => {
   let totpending = 0;
  const prepareResult = (datainputOrder,datainputProducts) =>{
    if(data.length > 0){
        //totla orders 
        let totals = datainputProducts?.reduce((a , el) => {return el.total + a},0)
        //total pending
        let filteredpending = datainputOrder?.filter(el => el.status === 'pending' || el.status === 'paied')
       
       
        //  filteredpending?.forEach(el =>  el?.items.forEach((els,it)=>{
        //   if(els.type === 'bundel' || els.type === 'package'){
        //     els.products?.forEach(elm => {return totpending += elm.count})
        //   } else {return totpending += els.count}
        //   })
        //   )
          

        //total paied
        let outStock = datainputProducts.filter(el => el.stock === 'out').reduce((a,el) => {return el.count + a},0)
        

        setResults((prev) => { return {...prev,
          total:totals,
          totalUnpaied:totpending,
          totalPied:'totalpaied',
          totalout:outStock,

        }})
    }
  }
  prepareResult(dataOrder,data)
},[data, dataOrder]);

  return (
    <div className='order'>
        <NavBar head={head}  />
        <div className="d-flex flex-column flex-wrap">
        <div className="col-12 order-types-filters-bar w-100 text-center d-flex text-capitalize">
          <div className="result-cards d-flex px-5 w-100 justify-content-between align-items-start">
            <div className="col-3 bg-primary bg-opacity-10 text-primary rounded-2 shadow h-100 px-2 py-1 pb-3 ">
<h4 className="h6 text-dark result-head text-start">
  Holding
</h4>
<h1 className="resultnum d-inline h3">
  {Results.totalUnpaied}  
</h1>


            </div>
            <div className="col-3 bg-success bg-opacity-10 text-success rounded-2 shadow h-100 px-2 py-1 pb-3">
<h4 className="h6 text-dark result-head text-start">
  Paied
</h4>
<h1 className="resultnum text-start ms-3 d-inline h3">
  {Results.totalPied}  
</h1>


            </div>
            <div className="col-3 bg-danger bg-opacity-10 text-danger rounded-2 shadow h-100 px-2 py-1 pb-3">
<h4 className="h6 text-dark result-head text-start">
  Out Off Stock
</h4>
<h1 className="resultnum d-inline h3">
  {Results.totalout || 0}  
</h1>

            </div>
          </div>
        </div>
        <div className="d-flex flex-row flex-wrap col-12 justify-content-between align-items-between">
        <div className="col-12  order-types-filters-bar text-center d-flex text-capitalize">
      {typeOrderd?.map((el,i)=>{
        return(
          <span key={i} onClick={(e)=>{setFilteredTypeOrder(e.target.dataset.filter)}} className={`col-md-2 col-3 order-types-filters-bar-item ${el.filter === filteredTypeOrder ? "active":""}`} data-filter={el.filter} >{el.filter}</span>
        )
      })}
    </div>
    <div className="col-12 col-md-6 order-types-filters-bar  text-center d-flex text-capitalize">
      {StageOrder?.map((el,i)=>{
        return(
          <span key={i} onClick={(e)=>{setFilteredStageOrder(e.target.dataset.filter)}} className={`col-md-2 col-3 order-types-filters-bar-item ${el.filter === filteredStageOrder ? "active":""}`} data-filter={el.filter} >{el.filter}</span>
        )
      })}
    </div>
    <div className="col-12 col-md-6 order-types-filters-bar text-center d-flex text-capitalize">
      {GenderOrder?.map((el,i)=>{
        return(
          <span key={i} onClick={(e)=>{setFilteredGenderOrder(e.target.dataset.filter)}} className={`col-md-2 col-3 order-types-filters-bar-item ${el.filter === filteredGenderOrder ? "active":""}`} data-filter={el.filter} >{el.filter}</span>
        )
      })}
    </div>
        </div>

    <div className="col-12 flex-wrap order-filter-other d-flex justify-content-between align-items-center">
        <div className="col-12 col-md-4 mb-3">

   
          <div className="search-bar-container d-flex justify-content-between align-items-between">
              <div className="input-container w-100">
                <input placeholder='Search by order id' value={serached} onChange={(e)=>setserached(e.target.value) } list='seacedId' type="text" className='form-control search-input' />
                <datalist id="seacedId">
                {data?.length > 0 ? 
                data.map((el,i)=> <option value={el.id} key={i} />)
                :''}
                </datalist>
              </div>
              <div className="icon">
                {serached.length === 0 ? 
                  <span>
                  <TbSearch />
                  </span>:
                  <span className='btn' onClick={()=> setserached('')}>
                  <TbX />
                  </span>
                }
              </div>
          </div>
        </div>
        <nav aria-label="Page navigation example">
  <ul className="pagination justify-content-end">
    <li className="page-item ">
      <button className="page-link" onClick={()=>{setindexshow(e=> e - 1)}} disabled={indexshow === 0} >Previous</button>
    </li>
    {data.length > 0 ? Array(Math.floor( data.length  / shownum) + 1).fill('0').map((el,i)=> {
     return   <li className={`page-item ${indexshow === i ? "active":""} `}onClick={()=>{setindexshow(i)}}><button className="page-link" >{i + 1}</button></li>
}
    ):""}

    <li className="page-item">
      <button className="page-link" onClick={()=>{setindexshow(e=> e + 1)}} >Next</button>
    </li>
  </ul>
</nav>
    </div>
    <div className="col-12 order-table table-responsive">
 <PBSTable tab={ {numIndex:indexshow, header:['id','name','gender','stage','size','count','action','reset'],data:serached?handelSearchedData():preparedData(preparedGenderData(preparedStageData(paginationData(data)))) ,}} access="view" /> 
    </div>
    </div>
    </div>
  )
}
