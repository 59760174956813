import React, { useState } from 'react'
import "./Navbar.css"
import {IoNotificationsOutline} from 'react-icons/io5'
import { useNavigate } from 'react-router'
import { signOut } from 'firebase/auth'
import { auth } from '../../firebase/Authentication'
export const NavBar = ({head}) => {
  const navigate = useNavigate()
 const handelLogOut = () =>{
              
    signOut(auth).then(() => {
    // Sign-out successful.
    localStorage.removeItem('auth');
        setTimeout(()=>{
          navigate('/login')
        },3000)
    }).catch((error) => {
    // An error happened.
    });

 }

  return (
<nav className="navbar custom-nav d-flex flex-wrap align-items-center justify-content-between px-5 pt-3 w-100 ">
    <div className="navbar-brand">
      {head}
    </div>
    <div class="navbar-nav  d-flex flex-wrap flex-row align-items-center justify-content-between">
    <span className="notification me-3">

        <IoNotificationsOutline />

    </span>




    <div class="dropdown">
  <button class="btn btn-secondary user" type="button" data-bs-toggle="dropdown" aria-expanded="false">
  
          <span className="live">
            
          </span>
        
  </button>
  <ul class="dropdown-menu custom-drop">
    <li className='btn btn-transparent ' onClick={handelLogOut}>Log out</li>
    
  </ul>
</div>








      </div>
</nav>
  )
}

