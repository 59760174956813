import React, { useState } from 'react'
import { Button, ButtonToolbar, Modal, Placeholder } from 'rsuite'

export const CustModal = ({body,head,color}) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  return (
    <>
    <ButtonToolbar>
      <Button appearance='primary' color={color || ''} onClick={handleOpen}> {head || "Open"}</Button>
    </ButtonToolbar>

    <Modal open={open} onClose={handleClose}>

      <Modal.Body>
        {body}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} color='' appearance="primary">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  </>
  )
}
