import React, { useEffect, useState } from 'react'
import { uploadImgToStorage } from '../../firebase/AddProductFun';
import { Tag } from 'rsuite';

export const CustUploader = ({arr,produstId,setSizeGuide,type}) => {
    const [imageUrl,setImg] = useState({});

    useEffect(() => {
        if(imageUrl.status === 'compelete' && type === 'productImg'){
            arr.push(imageUrl.message)
        }
        if(imageUrl.status === 'compelete' && type === 'sizeGuide'){
          setSizeGuide(imageUrl.message)
        }
    }
    , [arr, imageUrl.message, imageUrl.status, setSizeGuide,type])
    

  return (
        <div class=" mb-3 col-3 p-3">
  <input required type="file" className='form-control' id="inputGroupFile01" onChange={(e)=>uploadImgToStorage(e.target.files[0],produstId,setImg,)} />
  {imageUrl.status === 'compelete'?
  <img src={imageUrl.message} alt='lol' width={'100%'} />
  :
  <Tag color='green'>
    {imageUrl.status}
  </Tag>
  }
</div>
  )
}

