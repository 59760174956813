import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';


import 'react-toastify/dist/ReactToastify.css';
import '@tremor/react/dist'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Login } from './components/Login/Login';
import { AllUsers } from './components/usersControl/allUsers/AllUsers';
import { AddUsers } from './components/usersControl/allUsers/addusers/AddUsers';
import { LayOutContainer } from './components/LayOutContainer/LayOutContainer';
import { OrderManagment } from './components/OrderManagment/OrderManagment';
import { Pay } from './components/Commercial/Pay';

import { ProductsList } from './components/ProductsList/ProductsList';
import { AddBundel } from './components/AddBundel/AddBundel';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './schoolBackend/schoolFirebase';
import { useDispatch } from 'react-redux';
import { GetAllProducts } from './components/store/slices/allproducts';

import { CustLoader } from './components/CustLoader';
import { BundelList } from './components/BundelList/BundelList';
import { Dashbord } from './components/dashboard/Dashbord';
import { Products } from './components/Products/Products';
import { Bundels } from './components/Bundels/Bundels';
import { Packages } from './components/Packages/Packages';
import { PickedUp } from './components/PickUp/PickUp';
import { Inventory } from './components/Inventory/Inventory';
import { PrivateRoute } from './components/PrivateRoute';
import { ProtectInventory } from './components/ProtectInventory';
import { Protectfinance } from './components/Protectfinance';
import { ProtectAdmin } from './components/ProtectAdmin';
import { Home } from './components/Home/Home';
import { GetAllPackages } from './components/store/slices/allPackages';
import { GetAllUsers } from './components/store/slices/allUsers';
import { Refund } from './components/Refund/Refund';
import { Request } from './components/Request/Request';
import { Complete } from './components/compelete/Compelete';
import { Reports } from './components/Reports/Reports';
import { ProtectIt } from './components/ProtectIt';

function App() {
  const dispatch = useDispatch()



  const navigate = useNavigate()

const [auth,setAuth] = useState({})
  useEffect(() => {
    
    const items = JSON.parse(localStorage.getItem('auth'));
    if (items) {
      setAuth(items);
    } else if (!items){
      navigate('./login')
    }
  }, [])















  return (
      <>
      <Routes>
        <Route element={<Login />} path="/login" />
        <Route element={<PrivateRoute />}>
           <Route element={<LayOutContainer />} path="/" exact>
           <Route element={<Home />} path="/" />




          <Route element={<ProtectInventory />} >
          <Route element={<Complete head={'Completed Orders'} />} path="/complete" />
          <Route element={<Inventory head={'Store'} />} path="/inventory" />
          <Route element={<PickedUp head={'Pick Up'} />} path="/Pick-Up" />
          <Route element={<Request  head={'Request '} />} path="/request" />
          </Route>




          <Route element={<Protectfinance />} >
          <Route element={<Refund head={'Refund '} />} path="/refund" />
          <Route element={<OrderManagment head={'order management'} />} path="/order-management" />
          <Route element={<Reports head={'Reports management'} />} path="/reports-management" />
        <Route element={<Pay head={'Pay'} />} path="/Pay" />
          </Route>





          <Route element={<ProtectIt />} >
          <Route element={<AllUsers head={'all-users'} />} path="/users-List" />
<Route element={<AddUsers head={'Add Users'} />}  path="/add-users" />
          </Route>
          <Route element={<ProtectAdmin />} >
            
          <Route element={<Dashbord  />} path="/dashboard" />
<Route element={<Products head={'Add Product'} />} path="/Add-Products" />
<Route element={<Bundels head={'Add bundels'} />} path="/Add-bundels" /> 
 <Route element={<Packages head={'Add Packages'} />} path="/Add-packages" />
<Route element={<BundelList serverName="bundels" head={'bundels List'} />} path="/bundels-List" />
<Route element={<BundelList serverName="packages" head={'packages List'} />} path="/packages-List" />
<Route element={<ProductsList head={'Product List'} />} path="/Product-List" />

          </Route>
          <Route path="*" />
        </Route>
        </Route>

      </Routes>
     
         
    </>
  );
}

export default App;
