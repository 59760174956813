import { addDoc, collection, collectionGroup, doc, getDoc, getDocs, query } from "firebase/firestore";
import { db } from "../schoolBackend/schoolFirebase";

export  const getArrData = async (file) =>{

    const dataArr = query(collectionGroup(db, file));
    const querySnapshot =  await getDocs(dataArr,file);
    let arr = []
    querySnapshot.forEach((doc) => {
          arr.push({...doc.data(),id:doc.id})
    });
     return arr
  }

  export  const getSpecificIdData = async (file,id) =>{
      const docRef = doc(db, file, id);
    const docSnap = await getDoc(docRef);
    let data = {}
    if (docSnap.exists()) {
      data = {...docSnap.data(),id:id};
  return data
    
    } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
    }
    }